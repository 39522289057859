import {Box, HeaderFlex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Link} from 'react-router-dom'
import {Theme} from '../../app/theme'
import {LogoutButton} from '../../common/components/logout-button/LogoutButton'
import {AdminLayoutContext, AdminLayoutContextValue} from '../layout/AdminLayoutContext'
import {ReactComponent as Logo} from './digital-logo-small.svg'

export const AdminHeader = () => {
  const {theme} = useFela<Theme>()
  const {setHeaderHeight} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)

  React.useLayoutEffect(() => {
    setHeaderHeight(theme.layout.headerHeight)
  }, [setHeaderHeight, theme.layout.headerHeight])

  return (
    <HeaderFlex
      fullWidth
      bg="bgContentEmphasised"
      px={1}
      height={theme.layout.headerHeight}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box height={20} width={175}>
        <Link to="/">
          <Logo />
        </Link>
      </Box>
      <Box pr={2}>
        <LogoutButton />
      </Box>
    </HeaderFlex>
  )
}
