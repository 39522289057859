import {OegbvBaseTheme, oegbvBaseTheme} from '@oegbv/ui-shared'

interface Layout {
  headerHeight: number
  footerHeight: number
  navbarWidth: number
}

export interface Theme extends OegbvBaseTheme {
  readonly layout: Layout
}

const theme: Theme = {
  ...oegbvBaseTheme,
  layout: {
    headerHeight: 45,
    footerHeight: 30,
    navbarWidth: 350,
  },
}

export default theme
