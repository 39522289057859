import * as React from 'react'
import {ThemedIcon} from '../components/themed-icon/ThemedIcon'
import {ReactComponent as Svg} from './images/person-group.svg'

export function PersonGroupIcon() {
  return (
    <ThemedIcon size={22} active={true}>
      <Svg />
    </ThemedIcon>
  )
}
