import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'

interface Props {
  name: string
  actionItem?: React.ReactNode
  children?: React.ReactNode
}

export function DetailsSection({name, actionItem, children}: Props) {
  return (
    <Box p={4} fullWidth>
      <Flex fontSize="small" bold fullWidth>
        <Box>{name}</Box>
        {actionItem && (
          <Box style={{position: 'relative', top: -2}} pl={2} cursorPointer>
            {actionItem}
          </Box>
        )}
      </Flex>
      <Box>{children}</Box>
    </Box>
  )
}
