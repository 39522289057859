import {EbookResultDocument, KvResultDocument, LohndatenEintrag} from './user-management.types'

const RESOURCE_PREFIX = 'RESOURCE_'

export const UM_TYPE_KV = RESOURCE_PREFIX + 'KV'
export const UM_TYPE_LOHNDATEN = RESOURCE_PREFIX + 'LD'
export const UM_TYPE_BOOK = RESOURCE_PREFIX + 'BOOK'

export function deriveDocsetId(docsetLanguageId: string): string {
  const pos = docsetLanguageId.indexOf('_')
  return pos > 0 ? docsetLanguageId.substring(0, pos) : docsetLanguageId
}

export function deriveResourceIdFromKvResultDocument(kvResultDocument: KvResultDocument): string {
  const docsetId = deriveDocsetId(kvResultDocument.id)
  return `${UM_TYPE_KV}:${docsetId}`
}

export function deriveResourceIdFromEbookResultDocument(ebookResultDocument: EbookResultDocument): string {
  return `${UM_TYPE_BOOK}:${ebookResultDocument.properties?.isbn}`
}

export function deriveDocsetIdFromLohndatenEintrag(lohndatenEintrag: LohndatenEintrag): string {
  const lohndatenVersionId = lohndatenEintrag.link.id
  const pos = lohndatenVersionId.indexOf('_')
  return pos > 0 ? lohndatenVersionId.substring(0, pos) : lohndatenVersionId
}

export function deriveResourceIdFromLohndatenEintrag(lohndatenEintrag: LohndatenEintrag): string {
  const docsetId = deriveDocsetIdFromLohndatenEintrag(lohndatenEintrag)
  return `${UM_TYPE_LOHNDATEN}:${docsetId}`
}
