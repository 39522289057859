import {useDebouncedUserInput, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {ReactComponent as PersonGroupIcon} from '../../../common/icons/images/person-group.svg'
import {loadUserGroups$} from '../../state/user-management.services'
import {UserGroup} from '../../state/user-management.types'
import {BV_REQUEST_PARAMS, userManagementUserGroupPath} from '../../user-management.paths'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {UserGroupListItem} from './UserGroupListItem'

interface Props {
  userGroupId: string | null | undefined
}
interface UserGroupAutoCompleteItem extends AutoCompleteItem {
  userGroup: UserGroup
}

const convertUserGroupToAutoSuggest = (userGroup: UserGroup): UserGroupAutoCompleteItem => {
  return {
    text: userGroup.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    userGroup,
  }
}

export function UserGroupListPanel({userGroupId}: Props) {
  const filter = useQueryParam(BV_REQUEST_PARAMS.filter)
  const action = useQueryParam(BV_REQUEST_PARAMS.action)
  const history = useHistory()
  const [userInput, setUserInput] = React.useState<string>(filter || '')
  const [userGroupItems, setUserGroupItems] = React.useState<UserGroupAutoCompleteItem[]>([])
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)

  React.useEffect(() => {
    history.push(userManagementUserGroupPath(userInput, userGroupId, action))
  }, [history, userInput, userGroupId, action])

  React.useEffect(() => {
    loadUserGroups$(ajax, filter).subscribe((keycloakUsers) => {
      const nextKeycloakUserItems = keycloakUsers.userGroups
        ? keycloakUsers.userGroups.map(convertUserGroupToAutoSuggest)
        : []
      setUserGroupItems(nextKeycloakUserItems)
    })
  }, [filter])

  const onSelect = (user: UserGroup) => {
    history.push(userManagementUserGroupPath(filter, user.id, action))
  }

  return (
    <SearchInput
      inputValue={userInput}
      items={userGroupItems}
      placeholder="Filter by Name"
      search={() => undefined}
      reload={(prefix: string) => setDebouncedUserInput(prefix)}
      isSelectedSuggestion={(suggestion) => suggestion.userGroup.id === userGroupId}
      selectSuggestion={(suggestion) => onSelect(suggestion.userGroup)}
      renderItem={(item) => <UserGroupListItem userGroup={item.userGroup} filter={filter} />}
      renderItemIcon={() => <PersonGroupIcon />}
      styleConfig={{borderRadius: 0}}
      styles={SEARCH_INPUT_STYLE}
      alwaysShowMenu
    />
  )
}
