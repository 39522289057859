import {Box, Text, VStack} from '@indoqa/style-system'
import {useDebouncedUserInput} from '@oegbv/ui-shared'
import * as React from 'react'
import {ajax} from 'rxjs/ajax'
import {SearchInput} from '../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../common/components/search-input/SearchInput.types'
import {loadAemRecommendations, loadSearchResults} from '../state/aem.services'
import {Recommendations, ResultDocument} from '../state/aem.types'
import {AemSearchResultItemPanel} from './AemSearchResultItemPanel'
import {RecommendationsPanel} from './RecommendationsPanel'

interface SearchResultAutoCompleteItem extends AutoCompleteItem {
  resultDocument: ResultDocument
}

export const AemRecommendationsPage = () => {
  const [userInput, setUserInput] = React.useState<string>('')
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)
  const [items, setItems] = React.useState<SearchResultAutoCompleteItem[]>([])
  const [selectedDocument, setSelectedDocument] = React.useState<ResultDocument | null>(null)
  const [recommendations, setRecommendations] = React.useState<Recommendations | null>(null)

  React.useEffect(() => {
    if (!userInput) {
      setItems([])
      return
    }
    loadSearchResults(ajax, userInput).subscribe((searchResult) => {
      const {documents} = searchResult
      if (documents) {
        const autoSuggestItems = documents.map((resultDocument) => {
          const acItem: SearchResultAutoCompleteItem = {
            text: userInput,
            proposal: '',
            targetType: AutoCompleteItemTargetType.SUGGESTION,
            resultDocument,
          }
          return acItem
        })
        setItems(autoSuggestItems)
      }
    })
  }, [userInput])

  React.useEffect(() => {
    if (!selectedDocument) {
      return
    }
    loadAemRecommendations(ajax, selectedDocument.id).subscribe(setRecommendations)
  }, [selectedDocument])

  return (
    <VStack spacing={3} p={4}>
      <Box grow={1} pr={2} width={800}>
        <SearchInput<SearchResultAutoCompleteItem>
          items={items}
          placeholder="Search for an oegb.at document and get recommendations"
          search={() => undefined}
          reload={(prefix: string) => setDebouncedUserInput(prefix)}
          selectSuggestion={(suggestion) => {
            setSelectedDocument(suggestion.resultDocument)
          }}
          renderItem={(item) => <AemSearchResultItemPanel resultDocument={item.resultDocument} />}
          styleConfig={{borderRadius: 0}}
        />
      </Box>
      <Box maxWidth={1000} pb={2}>
        {selectedDocument && (
          <Box mb={4}>
            <Text bold color="red">
              Recommendations for this document:
            </Text>
            <Box p={2} bg="#DDD" r={2}>
              {<AemSearchResultItemPanel resultDocument={selectedDocument} />}
            </Box>
            <Box textAlign="right" cursorPointer>
              <Text onClick={() => setSelectedDocument(null)} underline>
                [clear]
              </Text>
            </Box>
          </Box>
        )}
        {!selectedDocument && <p>Nothing selected</p>}
        {selectedDocument && recommendations && <RecommendationsPanel recommendations={recommendations} />}
      </Box>
    </VStack>
  )
}
