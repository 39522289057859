/*
 Note:
 This component is included directly into the sources of this project to simplify the
 build process.

 License:
 Copyright 2019, Indoqa Software Design und Beratung GmbH

 Permission is hereby granted, free of charge, to any person obtaining a copy
 of this software and associated documentation files (the "Software"), to deal
 in the Software without restriction, including without limitation the rights
 to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 copies of the Software, and to permit persons to whom the Software is
 furnished to do so, subject to the following conditions:
 The above copyright notice and this permission notice shall be included in all
 copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
*/
import {Box, Flex, Text} from '@indoqa/style-system'
import {useCombobox, UseComboboxState, UseComboboxStateChangeOptions} from 'downshift'
import * as React from 'react'
import {useFela} from 'react-fela'
import {ThemedIcon} from '../themed-icon/ThemedIcon'
import {ReactComponent as CancelIcon} from './icons/clear.svg'
import {ReactComponent as SearchIcon} from './icons/search.svg'
import {
  DEFAULT_STYLE_CONFIG,
  styleCombobox,
  styleContainer,
  styleIconContainer,
  styleInput,
  styleMenu,
  styleMenuItem,
} from './SearchInput.styles'
import {
  AutoCompleteItem,
  AutoCompleteItemTargetType,
  Clear,
  OnCompletionSelected,
  Reload,
  RenderIcon,
  RenderItem,
  Search,
  SearchInputStyles,
  SelectSuggestion,
  StyleConfig,
  StyleSearchInputProps,
} from './SearchInput.types'

interface Props<T extends AutoCompleteItem> {
  items: T[]
  inputValue?: string
  placeholder?: string
  autofocus?: boolean
  styleConfig?: Partial<StyleConfig>
  styles?: Partial<SearchInputStyles>
  reload: Reload
  search: Search
  clear?: Clear
  showClear?: boolean
  renderItem?: RenderItem<T>
  renderItemIcon?: RenderItem<T>
  renderComboBoxItemIcon?: RenderIcon
  renderComboBoxSearchIcon?: RenderIcon
  renderCancelIcon?: RenderIcon
  selectSuggestion?: SelectSuggestion<T>
  isSelectedSuggestion?: (suggestion: T) => boolean
  onCompletionSelected?: OnCompletionSelected
  alwaysShowMenu?: boolean
}

export const createItemComponents = <T extends AutoCompleteItem>(item: T) => {
  const proposal = Array.isArray(item.proposal) ? item.proposal : [item.proposal]
  const text = Array.isArray(item.text) ? item.text : [item.text]
  const count = Math.max(proposal.length, text.length)
  const components = []
  for (let i = 0; i < count; i++) {
    if (i < text.length) {
      components.push(
        <span key={`t_${i}`} style={{whiteSpace: 'pre'}}>
          {text[i]}
        </span>
      )
    }
    if (i < proposal.length) {
      components.push(
        <strong key={`s_${i}`} style={{whiteSpace: 'pre'}}>
          {proposal[i]}
        </strong>
      )
    }
  }
  return components
}

const renderItemDefault = <T extends AutoCompleteItem>(item: T) => {
  return <>{createItemComponents(item)}</>
}

const renderCancelIconDefault = () => (
  <ThemedIcon size={14} color="#AAAAAA" hoverColor="#AAAAAA">
    <CancelIcon />
  </ThemedIcon>
)

const renderComboBoxSearchIconDefault = () => (
  <ThemedIcon size={20} color="#AAAAAA" hoverColor="#AAAAAA">
    <SearchIcon />
  </ThemedIcon>
)

const renderItemIconDefault = () => (
  <ThemedIcon size={16} color="#AAAAAA" hoverColor="#AAAAAA">
    <SearchIcon />
  </ThemedIcon>
)

const selectSuggestionDefault = () => undefined

const renderComboBoxItemIconDefault = <T extends AutoCompleteItem>(item: T) => renderItemIconDefault()

/**
 1. Input eingegeben
 2. Darstestellung Auto-Complete
 3. Fortgeführter Input führt zu aktualisiertem Auto-Complete
 4. Auswahl eines Completion-Vorschlags mit ARROW-DOWN/UP und dann ENTER
 5. Suche absetzen

 3.a. Fortgeführter Input führt zu leerem Auto-Complete
 3.b. letzte Eingabe mit BACKSPACE entfernen
 3.c. Zum Anfang der Eingabe mit POS1 springen
 3.d. Zum Ende der Eingabe mit END springen
 3.e.1 Die Vorschläge mit ESC ausblenden
 3.e.1 Die Vorschläge mit ESC wieder einblenden
 4.a.1 Auswahl eines Completion-Vorschlags mit SPACE
 4.a.2 Übernahme des Completion-Vorschlags
 4.a.3 Weiter mit 3
 4.b. Auswahl eines Completion-Vorschlags mit der Maus
 4.c. Auswahl eines Completion-Vorschlags mit ARROW-DOWN/UP und dann SPACE
 4.d. Auswahl eines Completion-Vorschlags/Suggest-Vorschlags mit ARROW-DOWN/UP und dann ESC
 4.e. Auswahl eines Suggest-Vorschlags mit der Maus
 4.f. Auswahl eines Suggest-Vorschlags mit ARROW-DOWN/UP und dann SPACE
 4.g. Auswahl eines Suggest-Vorschlags mit ARROW-DOWN/UP und dann ENTER
 */
export const SearchInput = <T extends AutoCompleteItem>(props: Props<T>) => {
  const {
    items,
    inputValue: passedInputValue,
    placeholder = '',
    autofocus,
    styleConfig,
    styles = {},
    search,
    clear,
    showClear,
    renderItem = renderItemDefault,
    renderItemIcon = renderItemIconDefault,
    renderComboBoxItemIcon = renderComboBoxItemIconDefault,
    renderComboBoxSearchIcon = renderComboBoxSearchIconDefault,
    renderCancelIcon = renderCancelIconDefault,
    selectSuggestion = selectSuggestionDefault,
    isSelectedSuggestion,
    reload,
    onCompletionSelected,
    alwaysShowMenu = false,
  } = props
  const {css} = useFela()

  // keep a reference to input values explicitly set by the user (in contrast to 'previews' created by scrolling the lists)
  const explicitInputValueRef = React.useRef('')

  // keep a reference to the HTML input field
  const inputRef = React.useRef<HTMLInputElement>(null)

  // keep state whether the search input has focus
  const [active, setActive] = React.useState(false)

  // Convert an item to a string using its text and its proposal
  const itemToString = (item: T) => {
    const proposal = Array.isArray(item.proposal) ? item.proposal : [item.proposal]
    const text = Array.isArray(item.text) ? item.text : [item.text]
    const count = Math.max(proposal.length, text.length)
    let itemAsString = ''
    for (let i = 0; i <= count; i++) {
      const currentText = i < text.length ? text[i] : ''
      const currentProposal = i < proposal.length ? proposal[i] : ''
      itemAsString = itemAsString + currentText + currentProposal
    }
    return itemAsString
  }

  // scrolling through the list changes the value of the input field
  const stateReducer = (
    state: UseComboboxState<AutoCompleteItem>,
    actionAndChanges: UseComboboxStateChangeOptions<AutoCompleteItem>
  ) => {
    const type = actionAndChanges.type as any
    if (type === '__input_keydown_arrow_down__' || type === '__input_keydown_arrow_up__' || type === 0 || type === 1) {
      const highlightedIndex = actionAndChanges.changes.highlightedIndex
      if (highlightedIndex === undefined) {
        return actionAndChanges.changes
      }

      const highlightedItem = items[highlightedIndex]
      if (!highlightedItem) {
        return actionAndChanges.changes
      }

      if (highlightedItem.targetType === AutoCompleteItemTargetType.SUGGESTION) {
        return {
          ...actionAndChanges.changes,
        }
      }

      return {
        ...actionAndChanges.changes,
        inputValue: itemToString(highlightedItem),
      }
    }
    return actionAndChanges.changes
  }

  // a search command qualifies as an explicit user input and closes the menu
  const internalSearch = (userInput: string) => {
    if (explicitInputValueRef.current) {
      explicitInputValueRef.current = userInput
    }
    search(userInput)
    closeMenu()
  }

  // trigger a reload passing the caret position
  const internalReload = (prefix: string) => {
    if (inputRef.current) {
      reload(prefix, inputRef.current.selectionStart)
    }
  }

  // select a suggested item
  const internalSelectSuggestion = (item: T) => {
    closeMenu()
    selectSuggestion(item)
  }

  // explicit user input changes triggers reloads and also qualify to be explicit changes
  const onInputValueChange = (changes: Partial<UseComboboxState<AutoCompleteItem>>) => {
    const {inputValue, highlightedIndex} = changes
    if (inputValue !== undefined && (highlightedIndex === undefined || highlightedIndex < 0)) {
      explicitInputValueRef.current = inputValue
      internalReload(inputValue)
    }
  }

  // use the Downshift combobox hook to use its state management
  const {
    isOpen,
    inputValue,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    setInputValue,
    selectedItem,
    closeMenu,
    openMenu,
  } = useCombobox<AutoCompleteItem>({
    items,
    itemToString,
    onInputValueChange,
    stateReducer,
  })

  React.useLayoutEffect(() => {
    if (passedInputValue !== null && passedInputValue !== undefined) {
      setInputValue(passedInputValue)
    } else {
      if (autofocus && inputRef.current) {
        inputRef.current.focus()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedInputValue])

  // provide constants used for styling
  const hasInput = inputRef !== null && inputRef.current !== null && inputRef.current.value.length > 0
  const showCompletions = alwaysShowMenu || (isOpen && hasInput && items.length > 0)

  // prepare the input field properties
  const inputProps = getInputProps({
    ref: inputRef,
    onFocus: () => {
      setActive(true)
      openMenu()
    },
    onBlur: () => {
      setActive(false)
    },
    onClick: () => {
      internalReload(inputValue)
    },
    onKeyDown: (event) => {
      // if there is no highlighted index, ENTER triggers a search using the current input value
      if (event.key === 'Enter') {
        if (highlightedIndex < 0) {
          internalSearch(inputValue)
        }
        // otherwise use the selection considering its type
        else {
          const highlightedItem = items[highlightedIndex]
          if (highlightedItem && highlightedItem.targetType === AutoCompleteItemTargetType.SUGGESTION) {
            internalSelectSuggestion(highlightedItem)
          } else {
            internalSearch(inputValue)
            onCompletionSelected?.(inputValue)
          }
        }
      }
      // Escape toggles the suggestions/completions
      else if (event.key === 'Escape') {
        ;(event as any).nativeEvent.preventDownshiftDefault = true
        if (isOpen) {
          setInputValue(explicitInputValueRef.current)
          closeMenu()
        } else {
          internalReload(inputValue)
          openMenu()
        }
      }
      // use these keys within the input field instead of the selection list
      else if (event.key === 'End' || event.key === 'Home') {
        ;(event as any).nativeEvent.preventDownshiftDefault = true
      }
      // arrowDown + arrowUp
      else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        return
      }
      // reload when moving around with the cursor
      else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        internalReload(inputValue)
      } else {
        if (highlightedIndex >= 0) {
          onCompletionSelected?.(inputValue)
        }
      }
    },
  })

  // click on a menu item
  const clickItem = (item: T) => {
    // suggestion
    if (item.targetType === AutoCompleteItemTargetType.SUGGESTION) {
      internalSelectSuggestion(item)
      return
    }
    // completion
    const userInput = itemToString(item)
    setInputValue(userInput)
    internalSearch(userInput)
    onCompletionSelected?.(userInput)
    if (inputRef.current) {
      inputRef.current.focus()
    }
    closeMenu()
  }

  // click the clear icon
  const onClickClear = () => {
    if (clear) {
      clear()
    }
    internalSearch('')
    setInputValue('')
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  // provide styling
  const mergedStyleConfig: StyleConfig = Object.assign({}, DEFAULT_STYLE_CONFIG, styleConfig)
  const styleProps: StyleSearchInputProps = {
    active,
    showCompletions,
  }
  const customContainerStyles = styles.styleContainer?.(mergedStyleConfig, styleProps) || {}
  const containerClassName = css(styleContainer(mergedStyleConfig, styleProps), customContainerStyles)

  const customComboBoxStyles = styles.styleComboBox?.(mergedStyleConfig, styleProps) || {}
  const comboBoxClassName = css(styleCombobox(mergedStyleConfig, styleProps), customComboBoxStyles)

  const customInputStyles = styles.styleInput?.(mergedStyleConfig, styleProps) || {}
  const inputClassName = css(styleInput(mergedStyleConfig, styleProps), customInputStyles)

  const customMenuStyles = styles.styleMenu?.(mergedStyleConfig, styleProps) || {}
  const menuClassName = css(styleMenu(mergedStyleConfig, styleProps), customMenuStyles)

  const customMenuItemStyles = styles.styleMenuItem?.(mergedStyleConfig, styleProps) || {}
  const menuItemClassName = css(styleMenuItem(mergedStyleConfig, styleProps), customMenuItemStyles)

  const customIconContainerStyles = styles.styleIconContainer
    ? styles.styleIconContainer(mergedStyleConfig, styleProps)
    : {}
  const iconContainerClassName = css(styleIconContainer(mergedStyleConfig, styleProps), customIconContainerStyles)

  const showClearIcon = showClear || (inputRef?.current && inputRef.current.value.length > 0)

  const getMenuItemStyle = (item: T, index: number) => {
    if (isSelectedSuggestion?.(item)) {
      return {backgroundColor: mergedStyleConfig.highlightColor, color: 'red'}
    }
    return highlightedIndex === index ? {backgroundColor: '#efefef'} : {}
  }

  return (
    <div className={containerClassName}>
      <div {...getComboboxProps()} className={comboBoxClassName}>
        <div className={iconContainerClassName}>{renderComboBoxItemIcon(selectedItem as T)}</div>
        <input
          autoComplete={false}
          spellCheck={false}
          placeholder={placeholder}
          className={inputClassName}
          {...inputProps}
        />
        <Box cursorPointer ml={2}>
          {showClearIcon && (
            <Text cursorPointer mr={2} onClick={onClickClear}>
              {renderCancelIcon()}
            </Text>
          )}
          {!mergedStyleConfig.showIconIcons && (
            <Text cursorPointer onClick={() => search(itemToString(selectedItem as T))}>
              {renderComboBoxSearchIcon()}
            </Text>
          )}
        </Box>
      </div>
      <ul className={menuClassName} {...getMenuProps()}>
        {showCompletions &&
          items.map((item, index) => (
            <li
              className={menuItemClassName}
              key={`${item.proposal}${index}`}
              style={getMenuItemStyle(item, index)}
              {...getItemProps({item, index})}
              onClick={() => clickItem(item)}
            >
              <Flex nowrap fullWidth>
                <div className={iconContainerClassName}>{renderItemIcon(item)}</div>
                {renderItem(item)}
              </Flex>
            </li>
          ))}
      </ul>
    </div>
  )
}
