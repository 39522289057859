import {Text} from '@indoqa/style-system'
import {authenticator} from '@oegbv/ui-shared'
import {IStyle} from 'fela'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

export const LogoutButton = () => {
  const {css, theme} = useFela<Theme>()

  const buttonStyle: IStyle = {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    border: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }
  const textStyle: IStyle = {
    display: 'none',
    tablet: {
      display: 'block',
      position: 'relative',
      color: theme.colors.text,
      marginRight: theme.spacing.space2,
    },
  }

  return (
    <button className={css(buttonStyle)} onClick={() => authenticator.logout()}>
      <Text<Theme> style={textStyle} fontStyle="brandBold">
        Logout
      </Text>
    </button>
  )
}
