import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {baseCellStyle} from './table.utils'

interface Props {
  headers: string[]
}

export const Table: React.FC<Props> = ({headers, children}) => {
  const {css, theme} = useFela<Theme>()
  const tableStyle: IStyle = {
    width: '100%',
    maxWidth: '100%',
  }
  return (
    <Box fullWidth maxWidth="100%">
      <table className={css(tableStyle)}>
        <thead>
          <tr>
            {headers.map((header, index) => {
              return (
                <th className={css(baseCellStyle(theme))} key={index}>
                  {header}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </Box>
  )
}
