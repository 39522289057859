import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {ajax} from 'rxjs/ajax'
import {mergeTables} from '../state/kv.services'
import {KvMergeTablesInput, Message} from '../state/kv.types'

export const KvMergeTablesPage = () => {
  const {css} = useFela()
  const [input, setInput] = React.useState<KvMergeTablesInput>({annotationTable: '', valueTable: ''})
  const [result, setResult] = React.useState('')
  const [errorMessages, setErrorsMessages] = React.useState<Message[]>([])
  React.useEffect(() => {
    if (!input) {
      return
    }
    mergeTables(ajax, input).subscribe((r) => {
      if (r.output) {
        setResult(r.output.trim())
        setErrorsMessages([])
      } else if (r.errors) {
        setResult('')
        setErrorsMessages(r.errors)
      }
    })
  }, [input])
  const inputStyle: IStyle = {
    width: '100%',
    height: '100%',
    resize: 'none',
  }
  const onAnnotationTableInputChange = (inputText?: string | null) => {
    setInput((prev) => ({...prev, annotationTable: inputText || ''}))
  }
  const onValueTableInputChange = (inputText?: string | null) => {
    setInput((prev) => ({...prev, valueTable: inputText || ''}))
  }
  return (
    <Flex fullWidth fullHeight minHeight={400}>
      <Flex height="80%" fullWidth>
        <Box grow={1} pr={2}>
          <textarea
            className={css(inputStyle)}
            onInput={(e) => onAnnotationTableInputChange(e.currentTarget.value)}
            placeholder="Enter the annotated table here."
          />
        </Box>
        <Box grow={1} px={2}>
          <textarea className={css(inputStyle)} readOnly value={result} />
        </Box>
        <Box grow={1} pl={2}>
          <textarea
            className={css(inputStyle)}
            onInput={(e) => onValueTableInputChange(e.currentTarget.value)}
            placeholder="Enter the new table here."
          />
        </Box>
      </Flex>
      <Flex fullWidth height="20%" pt={2} direction="column">
        {errorMessages.length > 0 && <h3 style={{color: 'red'}}>Errors</h3>}
        <ul>
          {errorMessages.map((message) => {
            return (
              <li key={message.message}>
                {message.message}
                <br />
                {message.parameters?.position && <pre style={{fontSize: '80%'}}>{message.parameters.position}</pre>}
              </li>
            )
          })}
        </ul>
      </Flex>
    </Flex>
  )
}
