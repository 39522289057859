import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {getHeaders} from '../../app/service-utils'
import {ArBooksResultDocument, ArSearchResult, ArWebCResultDocument} from './ar.types'

const BASE_PATH = '/api/v1/arfbr'

export const loadArBooks = (
  ajax: AjaxCreationMethod,
  page: number,
  pageSize: number
): Observable<ArSearchResult<ArBooksResultDocument>> => {
  return ajax.getJSON(`${BASE_PATH}/books?page=${page}&page-size=${pageSize}`, getHeaders())
}

export const loadArWebCDocuments = (
  ajax: AjaxCreationMethod,
  page: number,
  pageSize: number
): Observable<ArSearchResult<ArWebCResultDocument>> => {
  return ajax.getJSON(`${BASE_PATH}/webc?page=${page}&page-size=${pageSize}`, getHeaders())
}
