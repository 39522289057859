import {IndexingAction, IndexingActionKeys} from './indexing.actions'
import {IndexingSearchResult} from './indexing.types'

export interface IndexingState {
  result: IndexingSearchResult | null
}

const initialState: IndexingState = {
  result: null,
}

export const indexingReducer = (state: IndexingState = initialState, action: IndexingAction): IndexingState => {
  switch (action.type) {
    case IndexingActionKeys.LOAD_ENTRIES:
      return {
        result: null,
      }
    case IndexingActionKeys.LOAD_ENTRIES_SUCCESS:
      return {
        result: action.result,
      }
  }
  return state
}
