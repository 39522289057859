import {useQueryParam} from '@oegbv/ui-shared'

export const useCurrentPage = (): number => {
  const pageParam = useQueryParam('p')
  if (!pageParam) {
    return 0
  }
  try {
    return parseInt(pageParam, 10)
  } catch (e) {
    return 0
  }
}
