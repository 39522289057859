import queryString from 'query-string'

export const BASE_PATH = '/user-management'

export const BV_REQUEST_PARAMS = {
  filter: 'filter',
  userId: 'userId',
  userGroupId: 'userGroupId',
  action: 'action',
}

export const userManagementUserPath = (filter?: string | null, userId?: string | null) => {
  const queryParams = queryString.stringify({
    [BV_REQUEST_PARAMS.filter]: filter === '' ? undefined : filter,
    [BV_REQUEST_PARAMS.userId]: userId,
  })
  const queryParamsString = queryParams.toString()
  return `${BASE_PATH}/users${queryParamsString.length > 0 ? '?' : ''}${queryParamsString}`
}
export const userManagementUserGroupPath = (
  filter?: string | null,
  userGroupId?: string | null,
  action?: string | null | undefined
) => {
  const queryParams = queryString.stringify({
    [BV_REQUEST_PARAMS.filter]: filter === '' ? undefined : filter,
    [BV_REQUEST_PARAMS.userGroupId]: userGroupId,
    [BV_REQUEST_PARAMS.action]: action,
  })
  const queryParamsString = queryParams.toString()
  return `${BASE_PATH}/user-groups${queryParamsString.length > 0 ? '?' : ''}${queryParamsString}`
}
