import {Box} from '@indoqa/style-system'
import * as React from 'react'

const getFormattedDateTime = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  return `${year}${month}${day}-${hours}${minutes}`
}

export const KvExportsPage = () => {
  return (
    <Box p={4}>
      <h3>KV-Exports</h3>
      <Box pt={2}>
        <ul>
          <li>
            <a download={`docsets-export-${getFormattedDateTime()}.xlsx`} href={`/api/v1/kv/docset-infos-export`}>
              Docsets
            </a>
          </li>
          <li>
            <a download={`lohndaten-infos-${getFormattedDateTime()}.xlsx`} href={`/api/v1/kv/lohndaten-infos-export`}>
              Lohndaten-Infos
            </a>
          </li>
        </ul>
      </Box>
    </Box>
  )
}
