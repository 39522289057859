import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {UserGroup} from '../../state/user-management.types'
import {hl} from '../commons/Highlight.utils'

interface Props {
  userGroup: UserGroup
  filterValue: string
}

export function UserGroupSelectorItem({userGroup, filterValue}: Props) {
  return (
    <Flex fullWidth fontSize="small" pb={1} overflowHidden>
      <Box ellipsis pt={1}>
        {hl(userGroup.name || '', filterValue)}
      </Box>
    </Flex>
  )
}
