import {SearchInputStyles} from '../../../common/components/search-input/SearchInput.types'

export const SEARCH_INPUT_STYLE: Partial<SearchInputStyles> = {
  styleMenuItem: () => ({
    boxShadow: 'none',
  }),
  styleMenu: () => ({
    borderTop: '0px solid transparent',
    borderRight: '0px solid transparent',
    borderBottom: '0px solid transparent',
    borderLeft: '0px solid transparent',
    boxShadow: 'none',
  }),
  styleContainer: () => ({
    boxShadow: 'none',
  }),
  styleComboBox: (config) => ({
    borderBottom: config.borderStyle,
    marginBottom: 10,
  }),
  styleIconContainer: () => ({
    flexShrink: 0,
    flexGrow: 0,
  }),
  styleInput: () => ({
    fontSize: '90%',
  }),
}
