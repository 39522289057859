import {Box, Flex} from '@indoqa/style-system'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

interface Props {
  listPanel: React.ReactNode
  contentPanel: React.ReactNode
  bottomPanel?: React.ReactNode
}

export function UserManagementLayout({listPanel, bottomPanel, contentPanel}: Props) {
  const {theme} = useFela<Theme>()
  return (
    <Flex nowrap fullWidth fullHeight>
      <Flex direction="column" nowrap grow={0} width={300} p={2} style={{borderRight: theme.borders.border1}}>
        <Box fullHeight overflowHidden grow={1}>
          {listPanel}
        </Box>
        {bottomPanel && <Box grow={0}>{bottomPanel}</Box>}
      </Flex>
      <Box grow={1} style={{overflowY: 'auto'}}>
        {contentPanel}
      </Box>
    </Flex>
  )
}
