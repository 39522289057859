import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {KeycloakUser} from '../../state/user-management.types'
import {hl} from '../commons/Highlight.utils'

interface Props {
  user: KeycloakUser
  filterValue: string
}

export function UserSelectorItem({user, filterValue}: Props) {
  return (
    <Flex fullWidth fontSize="small" pb={1} overflowHidden>
      <Box ellipsis pt={1}>
        {hl(user.name || user.id, filterValue)}
      </Box>
    </Flex>
  )
}
