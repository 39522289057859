import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {getHeaders} from '../../app/service-utils'
import {AemSubtypes, IndexingSearchResult, ProviderTypes} from './indexing.types'

const INDEXED_BASE_PATH = '/api/v1/indexed'

export const loadAemEntries = (
  ajax: AjaxCreationMethod,
  start: number,
  count: number,
  site: string
): Observable<IndexingSearchResult> => {
  const siteParamValue = site === AemSubtypes.ALL ? '' : site
  return ajax.getJSON(`${INDEXED_BASE_PATH}/aem?start=${start}&count=${count}&site=${siteParamValue}`, getHeaders())
}

export const loadEntries = (
  ajax: AjaxCreationMethod,
  start: number,
  count: number,
  provider: string | null
): Observable<IndexingSearchResult> => {
  if (provider === null || provider === ProviderTypes.ALL) {
    return ajax.getJSON(`${INDEXED_BASE_PATH}?start=${start}&count=${count}`, getHeaders())
  }
  return ajax.getJSON(`${INDEXED_BASE_PATH}/${provider}?start=${start}&count=${count}`, getHeaders())
}
