import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {ajax} from 'rxjs/ajax'
import {validateFragment} from '../state/kv.services'
import {Message} from '../state/kv.types'

export const KVValidationPage = () => {
  const {css} = useFela()
  const [input, setInput] = React.useState('')
  const [result, setResult] = React.useState('')
  const [errorMessages, setErrorsMessages] = React.useState<Message[]>([])
  React.useEffect(() => {
    if (!input) {
      return
    }
    validateFragment(ajax, input).subscribe((r) => {
      if (r.output) {
        setResult(r.output.trim())
        setErrorsMessages([])
      } else if (r.messages) {
        setResult('')
        setErrorsMessages(r.messages)
      } else {
        setResult('')
        setErrorsMessages([{message: JSON.stringify(r, null, 2)}])
      }
    })
  }, [input])
  const inputStyle: IStyle = {
    width: '100%',
    height: '100%',
    resize: 'none',
  }
  const onInputChange = (inputText?: string | null) => {
    if (!inputText) {
      return
    }
    setInput(inputText)
  }
  return (
    <Flex fullWidth fullHeight minHeight={400}>
      <Flex height="80%" fullWidth>
        <Box grow={1} pr={2}>
          <textarea
            className={css(inputStyle)}
            onInput={(e) => onInputChange(e.currentTarget.value)}
            placeholder="Enter the CLS content here."
          />
        </Box>
        <Box grow={1} pl={2}>
          <textarea className={css(inputStyle)} readOnly value={result} />
        </Box>
      </Flex>
      <Flex fullWidth height="20%" pt={2} direction="column">
        {errorMessages.length > 0 && <h3 style={{color: 'red'}}>Errors</h3>}
        <ul>
          {errorMessages.map((message) => {
            return (
              <li key={message.message}>
                {message.message}
                <br />
                {message.parameters?.position && <pre style={{fontSize: '80%'}}>{message.parameters.position}</pre>}
              </li>
            )
          })}
        </ul>
      </Flex>
    </Flex>
  )
}
