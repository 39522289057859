import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  children: string
}

export function DetailsHeader({children}: Props) {
  const {theme} = useFela<Theme>()
  const headerStyle: IStyle = {
    borderBottom: theme.borders.border1,
    position: 'sticky',
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(1px)',
  }
  return (
    <Box fullWidth p={4} bold style={headerStyle} color="red">
      {children}
    </Box>
  )
}
