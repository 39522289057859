import {Box, Flex} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {Button} from '../../common/components/button/Button'
import {Table} from '../../common/components/table/Table'
import {cellNoWrap, cellWidth, formatDateTime} from '../../common/components/table/table.utils'
import {TableRow} from '../../common/components/table/TableRow'
import {kvDocsetsPath, KV_REQUEST_PARAMS} from '../kv.paths'
import {loadDocsets} from '../state/kv.services'
import {Docsets} from '../state/kv.types'

export const KvDocsetsPage = () => {
  const [docsets, setDocsets] = React.useState<Docsets | null>(null)
  const history = useHistory()
  const processingStateFilter = useQueryParam(KV_REQUEST_PARAMS.processingState)
  const aktuellFilter = useQueryParam(KV_REQUEST_PARAMS.aktuell)
  const mindestlohnFilter = useQueryParam(KV_REQUEST_PARAMS.mindestlohn)
  const fullFilter = useQueryParam(KV_REQUEST_PARAMS.full)

  React.useEffect(() => {
    loadDocsets(ajax, processingStateFilter, aktuellFilter, mindestlohnFilter, fullFilter).subscribe(setDocsets)
  }, [processingStateFilter, aktuellFilter, mindestlohnFilter, fullFilter])

  const renderRows = () => {
    if (!docsets || !docsets.infos) {
      return null
    }
    return docsets.infos.map((docset, index) => {
      const cellValues = [
        docset.id,
        docset.name,
        formatDateTime(docset.lastContentUpdate),
        // docset.lohndatenstatus || '-',
      ]
      const cellFormats = [[cellNoWrap(), cellWidth(30)], [cellWidth(500)], [cellWidth(100)], [cellWidth(50)]]
      return <TableRow key={index} index={index} cellValues={cellValues} cellFormats={cellFormats} />
    })
  }

  const renderDocumentsFound = () => {
    let text = `Es wurden ${docsets ? docsets.count : '0'} ${
      aktuellFilter === 'on' ? 'aktuelle' : ''
    } KV-Docsets gefunden`

    if (fullFilter === 'on') {
      text += `, die zumindest mit einer Zulage oder einem Zuschlag annotiert sind`
      if (mindestlohnFilter === 'on') {
        text += ` und Mindestlöhne enthalten.`
      } else if (mindestlohnFilter === 'off') {
        text += ` und keine Mindestlöhne enthalten.`
      } else {
        text += '.'
      }
    } else {
      if (mindestlohnFilter === 'on') {
        text += `, die Mindestlöhne enthalten.`
      } else if (mindestlohnFilter === 'off') {
        text += `, die keine Mindestlöhne enthalten.`
      }
    }

    return text
  }

  const headers = ['Docset-ID', 'Name', 'Last update' /*'State'*/]
  return (
    <Box fullWidth fullHeight>
      <Flex p={2} grow={0}>
        <Button
          name="Aktuell"
          selected={aktuellFilter === 'on'}
          onClick={() =>
            history.push(kvDocsetsPath('ALL', aktuellFilter === 'on' ? null : 'on', mindestlohnFilter, fullFilter))
          }
        />
        <Flex pl={6}>
          <Button
            name="Full (Zulage oder Zuschlag vorhanden)"
            selected={fullFilter === 'on'}
            onClick={() =>
              history.push(kvDocsetsPath('ALL', aktuellFilter, mindestlohnFilter, fullFilter === 'on' ? null : 'on'))
            }
          />
        </Flex>
        <Flex pl={6}>
          <Button
            name="Mindestlohn vorhanden"
            selected={mindestlohnFilter === 'on'}
            onClick={() =>
              history.push(kvDocsetsPath('ALL', aktuellFilter, mindestlohnFilter === 'on' ? 'null' : 'on', fullFilter))
            }
          />
          <Button
            name="Mindestlohn fehlt"
            selected={mindestlohnFilter === 'off'}
            onClick={() =>
              history.push(
                kvDocsetsPath('ALL', aktuellFilter, mindestlohnFilter === 'off' ? 'null' : 'off', fullFilter)
              )
            }
          />
        </Flex>
      </Flex>
      <Box pl={2}>{renderDocumentsFound()}</Box>
      <Table headers={headers}>{renderRows()}</Table>
    </Box>
  )
}
