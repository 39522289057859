import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  value: string
  onSave?: (nextValue: string) => void
}

export function UserDetailsField({value, onSave}: Props) {
  const {theme, css} = useFela<Theme>()
  const [inputValue, setInputValue] = React.useState(value)
  const [isDirty, setIsDirty] = React.useState(false)

  React.useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
    setIsDirty(event.target.value !== value)
  }

  return (
    <Flex>
      <Box fullWidth maxWidth={500} height={44} mr={2}>
        <input
          value={inputValue}
          onChange={handleChange}
          readOnly={!onSave}
          className={css({
            color: theme.colors.textReduced,
            border: theme.borders.border1,
            flexGrow: 1,
            width: '100%',
            padding: theme.spacing.space2,
          })}
        />
      </Box>
      {onSave && (
        <button
          onClick={() => {
            setIsDirty(false)
            onSave(inputValue)
          }}
          disabled={!isDirty || inputValue.length === 0}
        >
          Speichern
        </button>
      )}
    </Flex>
  )
}
