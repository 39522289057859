import {Box, FooterFlex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {getCurrentSetFromSessionStorage, saveCurrentSetToSessionStorage} from '../../app/service-utils'
import {Theme} from '../../app/theme'
import {AdminLayoutContext, AdminLayoutContextValue} from '../layout/AdminLayoutContext'

export const AdminFooter = () => {
  const {theme, css} = useFela<Theme>()
  const {setFooterHeight} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  React.useLayoutEffect(() => {
    setFooterHeight(theme.layout.footerHeight)
  }, [setFooterHeight, theme.layout.footerHeight])

  const radioInputStyle: IStyle = {
    marginRight: 5,
    marginLeft: 5,
    position: 'relative',
    top: 1,
    cursor: 'pointer',
  }

  return (
    <FooterFlex<Theme>
      px={2}
      py={1}
      bg="primaryLight"
      color="textInverted"
      fullWidth
      fontStyle="brand"
      fontSize="small"
      height={theme.layout.footerHeight}
      justifyContent="space-between"
      alignItems="center"
      style={{zIndex: 10}}
    >
      <Box>Verlag des ÖGB GmbH</Box>
      <Box>
        Set:
        <label htmlFor="live">
          <input
            name="live"
            type="radio"
            value="LIVE"
            className={css(radioInputStyle)}
            checked={getCurrentSetFromSessionStorage() === 'LIVE'}
            onChange={() => {
              saveCurrentSetToSessionStorage('LIVE')
              window.location.reload()
            }}
          />
          Live
        </label>
        <label htmlFor="preview">
          <input
            name="preview"
            type="radio"
            value="PREVIEW"
            className={css(radioInputStyle)}
            checked={getCurrentSetFromSessionStorage() === 'PREVIEW'}
            onChange={() => {
              saveCurrentSetToSessionStorage('PREVIEW')
              window.location.reload()
            }}
          />
          Preview
        </label>
      </Box>
    </FooterFlex>
  )
}
