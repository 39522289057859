import * as React from 'react'
import {ThemedIcon} from '../components/themed-icon/ThemedIcon'
import {ReactComponent as Svg} from './images/add.svg'

export function AddIcon() {
  return (
    <ThemedIcon size={18}>
      <Svg />
    </ThemedIcon>
  )
}
