import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {AdminLayoutContext, AdminLayoutContextValue} from '../../admin/layout/AdminLayoutContext'
import history from '../../app/history'
import {Button} from '../../common/components/button/Button'
import {Table} from '../../common/components/table/Table'
import {cellForceWrap, cellNoWrap, cellWidth, formatDateTime} from '../../common/components/table/table.utils'
import {TablePaging} from '../../common/components/table/TablePaging'
import {TableRow} from '../../common/components/table/TableRow'
import {useCurrentPage} from '../../common/components/table/useCurrentPage'
import {indexingWithSubTypePath} from '../indexing.paths'
import {indexingLoadEntries} from '../store/indexing.actions'
import {selectResult} from '../store/indexing.selectors'
import {AemSubtypes, ProviderTypes} from '../store/indexing.types'

const PAGE_SIZE = 50

interface ParamTypes {
  subType: string
  type: string
}

export const IndexingPage = () => {
  const dispatch = useDispatch()
  const {subType, type} = useParams<ParamTypes>()
  const currentPage = useCurrentPage()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const {setScrollToTop} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)

  React.useLayoutEffect(() => {
    setScrollToTop(new Date())
    if (type) {
      dispatch(indexingLoadEntries(type, subType || null, PAGE_SIZE * currentPage, PAGE_SIZE))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, subType, dispatch, currentPage])

  const result = useSelector(selectResult)

  const goToPage = (nextPage: number) => {
    const link = indexingWithSubTypePath(type || ProviderTypes.ALL, subType || null)
    history.push(link + (nextPage > 0 ? `?p=${nextPage}` : ''))
  }

  if (!result) {
    return <Box />
  }

  const filters = type === ProviderTypes.AEM && (
    <Flex pb={2}>
      <Button
        name="All"
        selected={!subType || subType === AemSubtypes.ALL}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.ALL))}
      />
      <Button
        name="GPA"
        selected={subType === AemSubtypes.GPA}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.GPA))}
      />
      <Button
        name="ÖGB"
        selected={subType === AemSubtypes.OEGB}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.OEGB))}
      />
      <Button
        name="ÖGJ"
        selected={subType === AemSubtypes.OEGJ}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.OEGJ))}
      />
      <Button
        name="Sichere Arbeit"
        selected={subType === AemSubtypes.SICHEREARBEIT}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.SICHEREARBEIT))}
      />
      <Button
        name="Younion"
        selected={subType === AemSubtypes.YOUNION}
        onClick={() => history.push(indexingWithSubTypePath(ProviderTypes.AEM, AemSubtypes.YOUNION))}
      />
    </Flex>
  )

  const headers = ['Indexed at', 'Provider', 'Name', 'Location']
  const renderRows = () => {
    return (
      <>
        {result.documents?.map((doc, index) => {
          const cellValues = [formatDateTime(doc.indexed), doc.provider, doc.name, doc.location]
          const cellFormats = [
            [cellNoWrap(), cellWidth(150)],
            [cellWidth(80)],
            [cellWidth(250), cellForceWrap()],
            [cellForceWrap()],
          ]
          return (
            <TableRow
              key={index}
              index={index}
              entryJson={JSON.stringify(doc, null, 2)}
              cellValues={cellValues}
              cellFormats={cellFormats}
            />
          )
        })}
      </>
    )
  }
  return (
    <Box innerRef={containerRef}>
      {filters}
      <Table headers={headers}>{renderRows()}</Table>
      <TablePaging
        goToPage={goToPage}
        pageSize={PAGE_SIZE}
        documentsFound={result.documentsFound ?? 0}
        currentPage={currentPage}
      />
    </Box>
  )
}
