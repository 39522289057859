import {IStyle} from 'fela'
import moment from 'moment'
import {Theme} from '../../../app/theme'

export const baseCellStyle = (theme: Theme): IStyle => ({
  padding: theme.spacing.space1,
})

export const cellNoWrap = (): IStyle => ({
  whiteSpace: 'nowrap',
})

export const cellWidth = (width: number | string): IStyle => ({
  width,
})

export const cellForceWrap = (): IStyle => ({
  whiteSpace: 'pre-wrap',
  overflowWrap: 'anywhere',
})

export const cellJsonCodeStyle = (): IStyle => ({
  fontSize: '80%',
  whiteSpace: 'pre-wrap',
})

export const formatDateTime = (date: Date): string => moment(date).format('YYYY-MM-DD HH:mm')

export const formatDate = (date: Date): string => moment(date).format('YYYY-MM-DD')
