import * as React from 'react'
import {ThemedIcon} from '../components/themed-icon/ThemedIcon'
import {ReactComponent as Svg} from './images/key.svg'

interface Props {
  color?: string
}

export function PermissionIcon({color}: Props) {
  return (
    <ThemedIcon size={22} color={color} active={true}>
      <Svg />
    </ThemedIcon>
  )
}
