import {Box, Flex} from '@indoqa/style-system'
import React from 'react'
import {PersonRoleIcon} from '../../../common/icons/PersonRoleIcon'

interface Props {
  role: string
}

export function UserRoleItem({role}: Props) {
  return (
    <Flex pt={1} fullWidth>
      <Box style={{position: 'relative', top: -4}} pr={2} grow={0}>
        <PersonRoleIcon />
      </Box>
      <Flex fontSize="small" grow={1}>
        {role}
      </Flex>
    </Flex>
  )
}
