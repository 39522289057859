import {Box, VStack} from '@indoqa/style-system'
import React from 'react'
import {AddIcon} from '../../../common/icons/AddIcon'
import {Permission} from '../../state/user-management.types'
import {DetailsSection} from '../commons/DetailsSection'
import {PermissionItem} from './PermissionItem'

interface Props {
  name: string
  permissions: Permission[]
  derivedPermissions: Permission[] | undefined
  showSelector: (show: boolean) => void
  removePermission: (permission: Permission) => void
}

export function PermissionSection({name, permissions, derivedPermissions, showSelector, removePermission}: Props) {
  return (
    <DetailsSection
      name={name}
      actionItem={
        <Box onClick={() => showSelector(true)}>
          <AddIcon />
        </Box>
      }
    >
      <VStack pt={2} pl={2}>
        {derivedPermissions?.map((permission) => {
          return <PermissionItem key={permission.resourceId} permission={permission} group />
        })}
      </VStack>
      <VStack pl={2}>
        {permissions.map((permission) => {
          return (
            <PermissionItem key={permission.resourceId} permission={permission} removePermission={removePermission} />
          )
        })}
      </VStack>
      {permissions.length === 0 && (!derivedPermissions || derivedPermissions.length === 0) && (
        <Box pl={2} fontSize="small">
          Keine
        </Box>
      )}
    </DetailsSection>
  )
}
