import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {UserGroup} from '../../state/user-management.types'
import {hl} from '../commons/Highlight.utils'

interface Props {
  userGroup: UserGroup
  filter: string | null | undefined
}
export function UserGroupListItem({userGroup, filter}: Props) {
  return (
    <Flex fontSize="small" pt={1} direction="column" nowrap pb={1} fullWidth overflowHidden>
      <Box ellipsis>{hl(userGroup.name || userGroup.id, filter)}</Box>
    </Flex>
  )
}
