import {Box, Flex, VStack} from '@indoqa/style-system'
import React from 'react'
import {AddIcon} from '../../../common/icons/AddIcon'
import {
  GroupPermissions,
  Permission,
  UserGroup,
  UserManagementResource,
  UserManagementUser,
} from '../../state/user-management.types'
import {DetailsHeader} from '../commons/DetailsHeader'
import {DetailsSection} from '../commons/DetailsSection'
import {UserGroupItem} from '../commons/UserGroupItem'
import {UserRoleItem} from '../commons/UserRoleItem'
import {PermissionDetails} from '../permissions/PermissionDetails'
import {UserGroupSelector} from '../user-group/UserGroupSelector'
import {UserDetailsField} from './UserDetailsField'

interface Props {
  user: UserManagementUser
  userGroups: UserGroup[]
  addPermission: (kvResource: UserManagementResource) => void
  removePermission: (permission: Permission) => void
  removeUserFromUserGroup: (userGroup: UserGroup) => void
  addUserToUserGroup: (userGroup: UserGroup, role: string) => void
}
const getUserDisplayName = (user: UserManagementUser) => {
  return user?.name || user?.id
}

function derivePermissionsFromUserGroups(userGroups: UserGroup[]): GroupPermissions {
  const result: GroupPermissions = {}
  userGroups.forEach((userGroup) => {
    result[userGroup.id] = {permissions: userGroup.permissions || [], userGroup}
  })
  return result
}

export function UserDetailsPanel({
  user,
  userGroups,
  addPermission,
  removePermission,
  addUserToUserGroup,
  removeUserFromUserGroup,
}: Props) {
  const [showUserGroupSelector, setShowUserGroupSelector] = React.useState(false)

  const derivedPermissions = derivePermissionsFromUserGroups(userGroups)

  return (
    <Flex direction="column" nowrap>
      <DetailsHeader>{getUserDisplayName(user) || ''}</DetailsHeader>

      <DetailsSection name="Benutzername">
        <UserDetailsField value={user?.name || ''}></UserDetailsField>
      </DetailsSection>

      <DetailsSection name="ID">
        <UserDetailsField value={user?.id || ''}></UserDetailsField>
      </DetailsSection>

      <DetailsSection name="Vorname">
        <UserDetailsField value={user.firstName || ''}></UserDetailsField>
      </DetailsSection>

      <DetailsSection name="Nachname">
        <UserDetailsField value={user.lastName || ''}></UserDetailsField>
      </DetailsSection>

      <DetailsSection name="Rollen (Keycloak)">
        <VStack pt={2} pl={2}>
          {user.roles?.map((role) => {
            return <UserRoleItem role={role} />
          })}
        </VStack>
        {(!user.roles || user.roles.length === 0) && (
          <Box pl={2} fontSize="small">
            Keine
          </Box>
        )}
      </DetailsSection>

      <DetailsSection
        name="Benutzergruppen"
        actionItem={
          <Box onClick={() => setShowUserGroupSelector(true)}>
            <AddIcon />
          </Box>
        }
      >
        <VStack pt={2} pl={2}>
          {user.userGroups?.map((userGroup) => {
            return (
              <UserGroupItem
                key={userGroup.id}
                userGroup={userGroup}
                removeUserFromUserGroup={removeUserFromUserGroup}
              />
            )
          })}
        </VStack>
        {(!user.userGroups || user.userGroups.length === 0) && (
          <Box pl={2} fontSize="small">
            Keine
          </Box>
        )}
      </DetailsSection>

      <PermissionDetails
        permissions={user.permissions}
        derivedPermissions={derivedPermissions}
        addPermission={addPermission}
        removePermission={removePermission}
      />

      <UserGroupSelector
        show={showUserGroupSelector}
        toggleShow={setShowUserGroupSelector}
        onSelect={(userGroup) => {
          addUserToUserGroup(userGroup, 'OWNER')
          setShowUserGroupSelector(false)
        }}
      />
    </Flex>
  )
}
