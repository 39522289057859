import {VStack} from '@indoqa/style-system'
import React from 'react'
import {AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {loadEbooks$, loadKvs$, loadLohndaten$} from '../../state/user-management.services'
import {
  EbookResultDocument,
  EBookSearchResult,
  GroupPermissions,
  KvSearchResult,
  Lohndaten,
  Permission,
  UserManagementResource,
} from '../../state/user-management.types'
import {
  deriveDocsetId,
  deriveDocsetIdFromLohndatenEintrag,
  deriveResourceIdFromEbookResultDocument,
  deriveResourceIdFromKvResultDocument,
  deriveResourceIdFromLohndatenEintrag,
  UM_TYPE_BOOK,
  UM_TYPE_KV,
  UM_TYPE_LOHNDATEN,
} from '../../state/user-management.utils'
import {DetailsSection} from '../commons/DetailsSection'
import {PermissionItem} from './PermissionItem'
import {PermissionSection} from './PermissionSection'
import {PermissionSelector} from './PermissionSelector'

interface Props {
  permissions?: Permission[]
  derivedPermissions?: GroupPermissions
  addPermission: (kvResource: UserManagementResource) => void
  removePermission: (permission: Permission) => void
}

interface GroupedPermissions {
  kv: Permission[]
  ld: Permission[]
  books: Permission[]
  unknown: Permission[]
}
const groupUserPermissions = (permissions: Permission[] = []) => {
  const result: GroupedPermissions = {
    kv: [],
    ld: [],
    books: [],
    unknown: [],
  }
  permissions.forEach((permission) => {
    if (permission.resourceId.startsWith(UM_TYPE_KV)) {
      result.kv.push(permission)
    } else if (permission.resourceId.startsWith(UM_TYPE_LOHNDATEN)) {
      result.ld.push(permission)
    } else if (permission.resourceId.startsWith(UM_TYPE_BOOK)) {
      result.books.push(permission)
    } else {
      result.unknown.push(permission)
    }
  })
  result.kv.sort((a, b) => a.resourceName.localeCompare(b.resourceName))
  result.ld.sort((a, b) => a.resourceName.localeCompare(b.resourceName))
  result.books.sort((a, b) => a.resourceName.localeCompare(b.resourceName))
  result.unknown.sort((a, b) => a.resourceName.localeCompare(b.resourceName))

  return result
}

const groupUserGroupPermissions = (groupPermissions: GroupPermissions): GroupedPermissions => {
  console.log('groupPermissions', groupPermissions)
  const result = Object.values(groupPermissions).reduce((acc, current) => {
    return acc.concat(current.permissions)
  }, [] as Permission[])
  console.log('result', result)
  return groupUserPermissions(result)
}

function getAngArbName(values?: string[]): string {
  let result = ''
  if (!values || values.length === 0) {
    return result
  }
  if (values.length === 1 && values.includes('ARB')) {
    result += ' (Arb.)'
  } else if (values.length === 1 && values.includes('ANG')) {
    result += ' (Ang.)'
  } else {
    result = ' (Arb./Ang.)'
  }
  return result
}

const mapKvSearchResultToResources = (kvSearchResult: KvSearchResult) => {
  if (!kvSearchResult || !kvSearchResult.documents) {
    return []
  }

  return kvSearchResult.documents.map((doc) => ({
    text: doc.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    resource: {
      id: deriveResourceIdFromKvResultDocument(doc),
      name: doc.name + getAngArbName(doc.properties?.ang_arb) + `, Docset: ${deriveDocsetId(doc.id)}`,
      originalId: doc.id,
    },
  }))
}

const mapLohndatenResultToResources = (lohndatenResult: Lohndaten) => {
  return lohndatenResult.lohndateneinträge.map((eintrag) => ({
    text: eintrag.link.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    resource: {
      id: deriveResourceIdFromLohndatenEintrag(eintrag),
      name: eintrag.link.name + `, Docset: ${deriveDocsetIdFromLohndatenEintrag(eintrag)}`,
      originalId: eintrag.link.id,
    },
  }))
}

const createBookName = (doc: EbookResultDocument) => {
  let result = doc.name
  if (doc.properties?.untertitel) {
    result += ` - ${doc.properties.untertitel}`
  }
  if (doc.properties?.rechtsstand) {
    result += `, ${doc.properties.rechtsstand}`
  }
  if (doc.properties?.isbn) {
    result += `, ISBN: ${doc.properties.isbn}`
  }
  return result
}
const mapEbookResultToResources = (eBookSearchResult: EBookSearchResult) => {
  if (!eBookSearchResult || !eBookSearchResult.documents) {
    return []
  }
  return eBookSearchResult.documents.map((doc) => ({
    text: doc.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    resource: {
      id: deriveResourceIdFromEbookResultDocument(doc),
      name: createBookName(doc),
      originalId: doc.id,
    },
  }))
}

export function PermissionDetails({permissions, derivedPermissions, addPermission, removePermission}: Props) {
  const [showKvSelector, setShowKvSelector] = React.useState(false)
  const [showLohndatenSelector, setShowLohndatenSelector] = React.useState(false)
  const [showEbookSelector, setShowEbookSelector] = React.useState(false)

  const groupedUserPermissions = groupUserPermissions(permissions)
  const groupedDerivedPermissions = derivedPermissions ? groupUserGroupPermissions(derivedPermissions) : undefined

  return (
    <>
      <PermissionSection
        name="Berechtigungen Kollektivverträge"
        permissions={groupedUserPermissions.kv}
        derivedPermissions={groupedDerivedPermissions?.kv}
        showSelector={setShowKvSelector}
        removePermission={removePermission}
      />

      <PermissionSection
        name="Berechtigungen Lohndatensätze"
        permissions={groupedUserPermissions.ld}
        derivedPermissions={groupedDerivedPermissions?.ld}
        showSelector={setShowLohndatenSelector}
        removePermission={removePermission}
      />

      <PermissionSection
        name="Berechtigungen E-Books"
        permissions={groupedUserPermissions.books}
        derivedPermissions={groupedDerivedPermissions?.books}
        showSelector={setShowEbookSelector}
        removePermission={removePermission}
      />

      {groupedUserPermissions.unknown.length > 0 && (
        <DetailsSection name="Sonstige">
          <VStack pt={2} pl={2}>
            {groupedUserPermissions.unknown.map((permission) => {
              return (
                <PermissionItem
                  key={permission.resourceId}
                  permission={permission}
                  removePermission={removePermission}
                />
              )
            })}
          </VStack>
        </DetailsSection>
      )}

      <PermissionSelector
        headerTitle="Wähle einen Kollektivvertrag aus:"
        show={showKvSelector}
        toggleShow={setShowKvSelector}
        onSelect={addPermission}
        mapToUserManagementResources={mapKvSearchResultToResources}
        load$={loadKvs$}
      />
      <PermissionSelector
        headerTitle="Wähle einen Lohndatensatz aus:"
        show={showLohndatenSelector}
        toggleShow={setShowLohndatenSelector}
        onSelect={addPermission}
        mapToUserManagementResources={mapLohndatenResultToResources}
        load$={loadLohndaten$}
      />
      <PermissionSelector
        headerTitle="Wähle ein E-Book aus:"
        show={showEbookSelector}
        toggleShow={setShowEbookSelector}
        onSelect={addPermission}
        mapToUserManagementResources={mapEbookResultToResources}
        load$={loadEbooks$}
      />
    </>
  )
}
