import {VStack} from '@indoqa/style-system'
import {Recommendations} from '../state/aem.types'
import {RecommendationPanel} from './RecommendationPanel'

interface Props {
  recommendations: Recommendations
}

export const RecommendationsPanel = ({recommendations}: Props) => (
  <VStack spacing={4}>
    {recommendations.recommendations.map((recommendation) => (
      <RecommendationPanel key={recommendation.document.id} recommendation={recommendation} />
    ))}
  </VStack>
)
