import {Box, Flex, HStack, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import parseUrl from 'parse-url'
import React from 'react'
import {useFela} from 'react-fela'
import {ResultDocument} from '../state/aem.types'

interface Props {
  resultDocument: ResultDocument
}

const imageStyle: IStyle = {
  display: 'inline-block',
  position: 'relative',
  minWidth: 150,
  minHeight: 84,
  width: 150,
  height: 84,
  textAlign: 'center',
  '&::before': {
    content: '""',
    width: '100%',
    height: 'calc(100% + 10px)',
    backgroundColor: '#ccc',
    borderRadius: 2,
    position: 'absolute',
    top: '50%',
    left: '-2px',
    transform: 'translateY(-50%)',
  },
  '&::after': {
    content: '"Not available"',
    fontSize: 12,
    color: 'rgb(100, 100, 100)',
    display: 'block',
    position: 'absolute',
    zIndex: 2,
    top: 30,
    left: 0,
    width: '100%',
    height: '100%',
  },
}

const renderPathElements = (pathElements: string | string[]) => {
  if (!Array.isArray(pathElements)) {
    return <Text>-</Text>
  }
  const result = []
  for (let i = 0; i < pathElements.length - 1; i++) {
    const pathElement = pathElements[i]
    result.push(<Text key={pathElement}>{pathElement}</Text>)
  }
  return result
}

/**
 * Image styling: https://dev.to/sasscrafter/how-to-style-broken-images-with-css-4il2
 */
export const AemSearchResultItemPanel = ({resultDocument}: Props) => {
  const {css} = useFela()
  const baseUrl = parseUrl(resultDocument.id)
  const imageUrl = `${baseUrl.protocol}://${baseUrl.resource}${resultDocument.properties['previewImage']}`
  return (
    <Box pl={3}>
      <Flex nowrap fullWidth>
        <Box grow={1}>
          <Text bold>{resultDocument.name}</Text>
          <div dangerouslySetInnerHTML={{__html: resultDocument.shortText}} />
        </Box>
        <Box ml={2} mr={1} my={2} grow={0}>
          <img src={imageUrl} alt="img" className={css(imageStyle)} />
        </Box>
      </Flex>
      <Flex fontSize="small">
        <Text pr={2}>Topic:</Text>
        <HStack divider={<Text>/</Text>}>{renderPathElements(resultDocument.properties['pathElements'])}</HStack>
      </Flex>
    </Box>
  )
}
