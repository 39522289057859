import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {KeycloakUser} from '../../state/user-management.types'
import {hl} from '../commons/Highlight.utils'
import {getRealname} from './getRealName'

interface Props {
  user: KeycloakUser
  filter: string | null | undefined
}
export function UserListItem({user, filter}: Props) {
  const realname = hl(getRealname(user), filter)
  return (
    <Flex fontSize="small" pt={1} direction="column" nowrap pb={1} fullWidth overflowHidden>
      {realname && <Box>{realname}</Box>}
      <Box ellipsis>{hl(user.name, filter)}</Box>
    </Flex>
  )
}
