import {Box, Flex} from '@indoqa/style-system'
import React from 'react'
import {DeleteIcon} from '../../../common/icons/DeleteIcon'
import {PersonGroupIcon} from '../../../common/icons/PersonGroupIcon'
import {UserManagementUser} from '../../state/user-management.types'
import {getRealname} from './getRealName'

interface Props {
  user: UserManagementUser
  removeUserFromUserGroup: (user: UserManagementUser) => void
}

const getUserDisplayName = (user: UserManagementUser) => {
  const realname = getRealname(user)
  if (realname) {
    return `${realname} (${user.name})`
  }
  return user.name
}

export function UserItem({user, removeUserFromUserGroup}: Props) {
  const [hover, setHover] = React.useState(false)

  return (
    <Flex pt={1} onMouseOver={() => setHover(true)} fullWidth htmlAttrs={{onMouseLeave: () => setHover(false)}}>
      <Box style={{position: 'relative', top: -4}} pr={2} grow={0}>
        <PersonGroupIcon />
      </Box>
      <Flex fontSize="small" grow={1}>
        {getUserDisplayName(user)}
        {hover && (
          <Box
            ml={2}
            style={{position: 'relative', top: -2}}
            cursorPointer
            onClick={() => removeUserFromUserGroup(user)}
          >
            <DeleteIcon />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
