import {Box, Flex} from '@indoqa/style-system'
import useEventListener from '@use-it/event-listener'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {Portal} from '../portals/Portal'

interface Props {
  id?: string
  show: boolean
  onRequestClose: () => void
  header?: JSX.Element
  footer?: JSX.Element
  headerHeight?: number
  footerHeight?: number
  windowStyle?: IStyle
  contentStyle?: IStyle
  veilStyle?: IStyle
}

export const ModalWindow: React.FC<Props> = ({
  id = 'modal-window',
  show,
  header,
  footer,
  headerHeight = 34,
  footerHeight = 44,
  windowStyle,
  contentStyle,
  veilStyle,
  onRequestClose,
  children,
}) => {
  const {theme} = useFela<Theme>()

  useEventListener('keydown', (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onRequestClose()
    }
  })

  const mergedWindowStyle: IStyle = {
    height: '70vh',
    width: '80vw',
    maxWidth: 1000,
    maxHeight: 650,
    position: 'fixed',
    backgroundColor: theme.colors.bgModalWindow,
    boxShadow: theme.shadows.contextMenu,
    ...windowStyle,
  }
  const mergedContentStyle: IStyle = {
    overflowY: 'auto',
    height: `calc(100% - ${headerHeight}px - ${footer ? footerHeight : 0}px)`,
    textAlign: 'left',
    ...contentStyle,
  }
  const mergedVeilStyle: IStyle = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.bgModalWindowVeil,
    ...veilStyle,
    zIndex: 2000,
  }
  return (
    <Portal id={id}>
      <Flex center style={mergedVeilStyle} onClick={onRequestClose}>
        <Flex style={mergedWindowStyle} direction="column" onClick={(e) => e.stopPropagation()}>
          <Flex fullWidth bg={theme.colors.bgModalWindowHeader} alignItems="center" height={headerHeight}>
            {header}
          </Flex>
          <Box grow={1} style={mergedContentStyle}>
            {children}
          </Box>
          {footer && (
            <Flex fullWidth bg={theme.colors.bgModalWindowFooter} alignItems="center" height={footerHeight}>
              {footer}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Portal>
  )
}
