import queryString from 'query-string'
import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {map} from 'rxjs/operators'
import {getHeaders} from '../../app/service-utils'
import {Docsets, KvMergeTablesInput, KvMergeTablesResult, KvValidationResult} from './kv.types'

const BASE_PATH = '/api/v1/kv'

function today() {
  const currentDate = new Date()
  return (
    currentDate.getFullYear() +
    '-' +
    String(currentDate.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(currentDate.getDate()).padStart(2, '0')
  )
}
export const validateFragment = (ajax: AjaxCreationMethod, input: string): Observable<KvValidationResult> => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'text/plain',
  }
  return ajax
    .post(`${BASE_PATH}/editing-tools/validate-fragment`, input, headers)
    .pipe(map((result) => result.response))
}

export const mergeTables = (ajax: AjaxCreationMethod, input: KvMergeTablesInput): Observable<KvMergeTablesResult> => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'application/json',
  }
  return ajax.post(`${BASE_PATH}/editing-tools/merge-tables`, input, headers).pipe(map((result) => result.response))
}

export const loadDocsets = (
  ajax: AjaxCreationMethod,
  lohndatenstatusFilter?: string | null,
  aktuellFilter?: string | null,
  mindestlohnFilter?: string | null,
  fullFilter?: string | null
): Observable<Docsets> => {
  const queryParams: {[key: string]: string} = {}
  if (lohndatenstatusFilter && lohndatenstatusFilter !== 'ALL') {
    queryParams.lohndatenstatus = lohndatenstatusFilter
  }
  if (aktuellFilter === 'on') {
    queryParams['valid-on'] = today()
  }
  if (mindestlohnFilter === 'on') {
    queryParams.mindestlohn = 'true'
  }
  if (mindestlohnFilter === 'off') {
    queryParams.mindestlohn = 'false'
  }
  if (fullFilter === 'on') {
    queryParams.full = 'true'
  }
  const params = queryString.stringify(queryParams)
  const paramsWithSeparator = params ? `&${params}` : ''
  return ajax.getJSON(`${BASE_PATH}/docset-infos?language=de&sort=name${paramsWithSeparator}`, getHeaders())
}
