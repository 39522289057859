import {createBrowserHistory, History} from 'history'
import PiwikReactRouter from 'piwik-react-router'

const piwikRouter = PiwikReactRouter({
  url: 'matomo.gewerkschaften-online.at/',
  siteId: 102,
})

const history: History =
  process.env.NODE_ENV === 'development' ? createBrowserHistory() : piwikRouter.connectToHistory(createBrowserHistory())

export default history
