import {Flex} from '@indoqa/style-system'
import {useEnableBodyClassReader} from '@oegbv/ui-shared'
import {IStyle} from 'fela'
import * as React from 'react'
import {AdminLayoutContent} from './AdminLayoutContent'
import {AdminLayoutContext, AdminLayoutContextValue, Dimensions} from './AdminLayoutContext'
import {AdminLayoutFooter} from './AdminLayoutFooter'
import {AdminLayoutHeader} from './AdminLayoutHeader'
import {AdminLayoutNavbar} from './AdminLayoutNavbar'

interface Props {
  header: React.ReactNode
  nav?: React.ReactNode
  footer?: React.ReactNode
  children: React.ReactNode
}

export const AdminLayout = ({header, nav, footer, children}: Props) => {
  useEnableBodyClassReader()

  const [navShown, setNavShown] = React.useState(false)
  const [dimensions, setDimensions] = React.useState<Dimensions>({
    headerHeight: 0,
    footerHeight: 0,
    navWidth: 0,
  })
  const [scrollToTop, _setScrollToTop] = React.useState(() => new Date())

  // use callbacks with empty dependency arrays to avoid re-creating the
  // context manipulating functions (this would lead to executing the
  // effects that call them because their dependency arrays change)
  const toggleNav = React.useCallback(() => {
    setNavShown((currentNav) => !currentNav)
  }, [])
  const enableNav = React.useCallback(() => {
    setNavShown(true)
  }, [])
  const disableNav = React.useCallback(() => {
    setNavShown(false)
  }, [])
  const setHeaderHeight = React.useCallback((height: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      headerHeight: height,
    }))
  }, [])
  const setFooterHeight = React.useCallback((height: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      footerHeight: height,
    }))
  }, [])
  const setNavbarWidth = React.useCallback((width: number) => {
    setDimensions((currentDimensions) => ({
      ...currentDimensions,
      navWidth: width,
    }))
  }, [])
  const setScrollToTop = React.useCallback((value: Date) => {
    _setScrollToTop(value)
  }, [])

  const readerLayoutContextValue: AdminLayoutContextValue = {
    dimensions,
    navShown,
    scrollToTop,
    enableNav,
    disableNav,
    toggleNav,
    setHeaderHeight,
    setFooterHeight,
    setNavbarWidth,
    setScrollToTop,
  }

  const containerStyle: IStyle = {
    height: '100%',
    overflow: 'hidden',
    print: {
      display: 'block',
      overflow: 'visible',
      height: 'auto',
    },
  }
  const mainContainerStyle: IStyle = {
    height: `calc(100% - ${dimensions.headerHeight}px - ${dimensions.footerHeight}px)`,
    print: {
      display: 'block',
      overflow: 'visible',
      height: 'auto',
    },
  }
  return (
    <AdminLayoutContext.Provider value={readerLayoutContextValue}>
      <Flex fullWidth fullHeight direction="column" nowrap style={containerStyle}>
        <AdminLayoutHeader>{header}</AdminLayoutHeader>
        <Flex fullWidth grow={1} style={mainContainerStyle} nowrap>
          <AdminLayoutNavbar>{nav}</AdminLayoutNavbar>
          <AdminLayoutContent>{children}</AdminLayoutContent>
        </Flex>
        <AdminLayoutFooter>{footer}</AdminLayoutFooter>
      </Flex>
    </AdminLayoutContext.Provider>
  )
}
