import {Box} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'

interface Props {
  name: string
  onClick: () => void
  selected: boolean
}

export const Button: React.FC<Props> = ({name, selected, onClick}) => {
  const {theme} = useFela<Theme>()
  const style: IStyle = {
    backgroundColor: selected ? theme.colors.accent : theme.colors.primaryLight,
  }
  return (
    <Box style={style} py={1} px={2} fontSize="small" color="textInverted" r={3} mr={2} onClick={onClick} cursorPointer>
      {name}
    </Box>
  )
}
