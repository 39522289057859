import {Resizer} from '@indoqa-react-internal/components/lib/resizer'
import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {AdminLayoutContext, AdminLayoutContextValue} from './AdminLayoutContext'

const NAVBAR_WIDTH = 300

export const AdminLayoutNavbar: React.FC = ({children}) => {
  const {css} = useFela()
  const {navShown, setNavbarWidth} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  const scrollableContainerRef = React.useRef<HTMLDivElement>(null)
  const widthRef = React.useRef(NAVBAR_WIDTH)

  React.useLayoutEffect(() => {
    setNavbarWidth(NAVBAR_WIDTH)
  }, [setNavbarWidth])
  const onResize = (moveBy: number) => {
    if (scrollableContainerRef.current) {
      const moveTo = widthRef.current + moveBy
      const nextWidth = Math.max(200, Math.min(moveTo, window.innerWidth - 400))
      scrollableContainerRef.current.style.width = `${nextWidth}px`
      widthRef.current = nextWidth
    }
  }
  if (!children) {
    return null
  }

  const navStyle: IStyle = {
    display: navShown ? 'flex' : 'none',
    position: 'relative',
    tablet: {
      display: 'flex',
    },
    print: {
      display: 'none',
    },
  }

  const scrollableContainerStyle: IStyle = {
    height: '100%',
    overflowY: 'hidden',
    width: '100vw',
    tablet: {
      width: widthRef.current ? `${widthRef.current}px` : '100%',
    },
    print: {
      display: 'none',
    },
  }

  return (
    <Flex fullHeight grow={0} style={navStyle}>
      <Box fullHeight fullWidth>
        <Resizer onResize={onResize} />
        <div className={css(scrollableContainerStyle)} ref={scrollableContainerRef}>
          {children}
        </div>
      </Box>
    </Flex>
  )
}
