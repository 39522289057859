import {Box, Flex} from '@indoqa/style-system'
import {useDebouncedUserInput} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Observable} from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {Theme} from '../../../app/theme'
import {Button} from '../../../common/components/button/Button'
import {ModalWindow} from '../../../common/components/modal-window/ModalWindow'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem} from '../../../common/components/search-input/SearchInput.types'
import {UserManagementResource} from '../../state/user-management.types'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {PermissionSelectorItem} from './PermissionSelectorItem'

interface Props<T> {
  headerTitle: string
  show: boolean
  toggleShow: (nextShow: boolean) => void
  load$: (ajax: AjaxCreationMethod, userInput: string | undefined | null) => Observable<T>
  mapToUserManagementResources: (result: T) => ResourceAutoCompleteItem[]
  onSelect: (selectedResource: UserManagementResource) => void
}

export interface ResourceAutoCompleteItem extends AutoCompleteItem {
  resource: UserManagementResource
}

export function PermissionSelector<T>({
  headerTitle,
  show,
  toggleShow,
  onSelect,
  load$,
  mapToUserManagementResources,
}: Props<T>) {
  const {theme} = useFela<Theme>()
  const [userInput, setUserInput] = React.useState<string>('')
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)
  const [resources, setResources] = React.useState<ResourceAutoCompleteItem[]>([])

  React.useEffect(() => {
    setUserInput('')
  }, [show])
  React.useEffect(() => {
    if (show) {
      load$(ajax, userInput).subscribe((result) => {
        console.log('result', result)
        const nextResources = mapToUserManagementResources(result)
        setResources(nextResources)
      })
    }
  }, [userInput, show, load$, mapToUserManagementResources])

  return (
    <ModalWindow
      show={show}
      onRequestClose={() => toggleShow(false)}
      header={
        <Flex
          fullWidth
          fullHeight
          bg={theme.colors.border}
          textAlign="left"
          pl={2}
          fontSize="small"
          alignItems="center"
          bold
        >
          <Box>{headerTitle}</Box>
        </Flex>
      }
      footer={
        <Flex fullWidth justifyContent="flex-end" alignItems="baseline">
          <Box pr={4} fontSize="small" underline onClick={() => toggleShow(false)} cursorPointer>
            Abbrechen
          </Box>
          <Button
            name="Hinzufügen"
            onClick={() => {
              toggleShow(false)
            }}
            selected={false}
          />
        </Flex>
      }
    >
      <Flex p={3} fullWidth fullHeight>
        <Box fullWidth overflowHidden fullHeight>
          <SearchInput
            inputValue={userInput}
            items={resources}
            placeholder="Filtern nach Namen"
            search={() => undefined}
            reload={(prefix: string) => setDebouncedUserInput(prefix)}
            selectSuggestion={(suggestion) => {
              toggleShow(false)
              onSelect(suggestion.resource)
            }}
            renderItem={(item) => <PermissionSelectorItem resource={item.resource} filterValue={userInput} />}
            styleConfig={{borderRadius: 0}}
            styles={SEARCH_INPUT_STYLE}
            alwaysShowMenu
          />
        </Box>
      </Flex>
    </ModalWindow>
  )
}
