import {IRenderer} from 'fela'

const conduitITCProBoldFiles = [
  '/fonts/39DB1B_0_0.woff2',
  '/fonts/39DB1B_0_0.woff',
  '/fonts/39DB1B_0_0.ttf',
  '/fonts/39DB1B_0_0.eot',
]
const conduitITCProMediumFiles = [
  '/fonts/39DB1B_2_0.woff2',
  '/fonts/39DB1B_2_0.woff',
  '/fonts/39DB1B_2_0.ttf',
  '/fonts/39DB1B_2_0.eot',
]
const conduitITCProLightFiles = [
  '/fonts/39DB1B_1_0.woff2',
  '/fonts/39DB1B_1_0.woff',
  '/fonts/39DB1B_1_0.ttf',
  '/fonts/39DB1B_1_0.eot',
]

// const ptSansNarrowRegularFiles = ['/fonts/pt-sans-narrow-v18-latin-regular.woff2']
// const ptSansNarrowBoldFiles = ['/fonts/pt-sans-narrow-v18-latin-700.woff2']

export const loadFonts = (renderer: IRenderer) => {
  renderer.renderFont('ConduitITCPro', conduitITCProBoldFiles, {fontWeight: 500})
  renderer.renderFont('ConduitITCPro', conduitITCProMediumFiles, {fontWeight: 400})
  renderer.renderFont('ConduitITCPro', conduitITCProLightFiles, {fontWeight: 300})

  // renderer.renderFont('PT Sans Narrow', ptSansNarrowBoldFiles, {fontWeight: 700, fontDisplay: 'swap'})
  // renderer.renderFont('PT Sans Narrow', ptSansNarrowBoldFiles, {fontWeight: 500, fontDisplay: 'swap'})
  // renderer.renderFont('PT Sans Narrow', ptSansNarrowRegularFiles, {fontWeight: 400, fontDisplay: 'swap'})
  // renderer.renderFont('PT Sans Narrow', ptSansNarrowRegularFiles, {fontWeight: 300, fontDisplay: 'swap'})
}
