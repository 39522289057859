import * as React from 'react'

export const hl = (text: string | null, filterValue: string | null | undefined): React.ReactNode => {
  if (!text) {
    return null
  }
  if (!filterValue || filterValue.length <= 1) {
    return text
  }
  const regex = new RegExp(`(${filterValue})`, 'gi')
  const parts = text.split(regex)
  return parts.map((part, index) => (regex.test(part) ? <mark key={index}>{part}</mark> : part))
}
