import queryString from 'query-string'
import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {getHeaders} from '../../app/service-utils'
import {Recommendations, SearchResult} from './aem.types'

export const loadAemRecommendations = (ajax: AjaxCreationMethod, id: string): Observable<Recommendations> => {
  const queryParams = queryString.stringify({id})
  return ajax.getJSON(`/api/v1/aem/recommendations?${queryParams}`, getHeaders())
}

export const loadSearchResults = (ajax: AjaxCreationMethod, query: string): Observable<SearchResult> => {
  return ajax.getJSON(`/api/v1/aem/search?query=${query}&page=0&page-size=10&site=oegb`)
}
