import {Flex, Text} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useCallback} from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {UserGroupDetailsPanel} from '../components/user-group/UserGroupDetailsPanel'
import {UserGroupListPanel} from '../components/user-group/UserGroupListPanel'
import {UserManagementLayout} from '../layout/UserManagementLayout'
import {
  addUserGroupPermission$,
  addUserToUserGroup$,
  loadUserGroup$,
  removeUserFromUserGroup$,
  removeUserGroupPermission$,
  saveUserGroup$,
} from '../state/user-management.services'
import {
  KeycloakUser,
  Permission,
  UserGroup,
  UserGroupUpdate,
  UserManagementResource,
  UserManagementUser,
} from '../state/user-management.types'
import {BV_REQUEST_PARAMS, userManagementUserGroupPath} from '../user-management.paths'

export const UserManagementUserGroupPage = () => {
  const userGroupId = useQueryParam(BV_REQUEST_PARAMS.userGroupId)
  const filter = useQueryParam(BV_REQUEST_PARAMS.filter)
  const action = useQueryParam(BV_REQUEST_PARAMS.action)

  const [userGroup, setUserGroup] = React.useState<UserGroup | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [lastUpdate, setLastUpdate] = React.useState(new Date().getTime())
  const history = useHistory()

  const loadUserGroup = useCallback(() => {
    if (!userGroupId) {
      setUserGroup(null)
      return
    }

    setLoading(true)
    loadUserGroup$(ajax, userGroupId).subscribe((userGroup) => {
      setUserGroup(userGroup)
      setLoading(false)
    })
  }, [userGroupId])

  React.useEffect(() => {
    loadUserGroup()
  }, [loadUserGroup])

  const addPermission = React.useCallback(
    (resource: UserManagementResource) => {
      addUserGroupPermission$(ajax, userGroupId!, resource).subscribe(() => {
        loadUserGroup()
      })
    },
    [loadUserGroup, userGroupId]
  )
  const removePermission = React.useCallback(
    (permission: Permission) => {
      removeUserGroupPermission$(ajax, userGroupId!, permission).subscribe(() => {
        loadUserGroup()
      })
    },
    [loadUserGroup, userGroupId]
  )

  const addUserToUserGroup = React.useCallback(
    (user: KeycloakUser) => {
      addUserToUserGroup$(ajax, user.id, userGroup!, 'OWNER').subscribe(() => {
        loadUserGroup()
      })
    },
    [loadUserGroup, userGroup]
  )
  const removeUserFromUserGroup = React.useCallback(
    (user: UserManagementUser) => {
      removeUserFromUserGroup$(ajax, user.id!, userGroup!).subscribe(() => {
        loadUserGroup()
      })
    },
    [loadUserGroup, userGroup]
  )

  const saveUserGroup = React.useCallback(
    (userGroupUpdate: UserGroupUpdate) => {
      saveUserGroup$(ajax, userGroupUpdate).subscribe(() => {
        setLastUpdate(new Date().getTime())
        loadUserGroup()
        history.push(userManagementUserGroupPath(filter, userGroupUpdate.id))
      })
    },
    [filter, history, loadUserGroup]
  )

  const contentPanel =
    userGroup || action === 'new' ? (
      <UserGroupDetailsPanel
        userGroup={userGroup}
        addPermission={addPermission}
        removePermission={removePermission}
        addUserToUserGroup={addUserToUserGroup}
        removeUserFromUserGroup={removeUserFromUserGroup}
        saveUserGroup={saveUserGroup}
      />
    ) : userGroupId && !loading ? (
      <Flex fullHeight fullWidth center>
        Die Benutzergruppe mit der ID&nbsp;<Text color="red">{userGroupId}</Text>&nbsp;existiert nicht.
      </Flex>
    ) : null

  const bottomPanel = (
    <Flex center fullHeight fullWidth>
      <button
        onClick={() => {
          setUserGroup(null)
          history.push(userManagementUserGroupPath(filter, undefined, 'new'))
        }}
      >
        Neue Gruppe
      </button>
    </Flex>
  )

  return (
    <UserManagementLayout
      contentPanel={contentPanel}
      listPanel={<UserGroupListPanel key={lastUpdate} userGroupId={userGroupId} />}
      bottomPanel={bottomPanel}
    />
  )
}
