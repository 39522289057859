import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {AdminLayoutContext, AdminLayoutContextValue} from './AdminLayoutContext'

export const AdminLayoutHeader: React.FC = ({children}) => {
  const {dimensions} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  return (
    <Box fullWidth height={dimensions.headerHeight}>
      {children}
    </Box>
  )
}
