import {useDebouncedUserInput, useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {ReactComponent as PersonIcon} from '../../../common/icons/images/person.svg'
import {loadKeycloakUsers} from '../../state/user-management.services'
import {KeycloakUser} from '../../state/user-management.types'
import {BV_REQUEST_PARAMS, userManagementUserPath} from '../../user-management.paths'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {UserListItem} from './UserListItem'

interface Props {
  userId: string | null | undefined
}
interface UserAutoCompleteItem extends AutoCompleteItem {
  user: KeycloakUser
}

const convertKeycloakUserToAutoSuggest = (keycloakUser: KeycloakUser): UserAutoCompleteItem => {
  return {
    text: keycloakUser.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    user: keycloakUser,
  }
}

export function UserListPanel({userId}: Props) {
  const filter = useQueryParam(BV_REQUEST_PARAMS.filter)
  const history = useHistory()
  const [userInput, setUserInput] = React.useState<string>(filter || '')
  const [keycloakUserItems, setKeycloakUserItems] = React.useState<UserAutoCompleteItem[]>([])
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)

  React.useEffect(() => {
    history.push(userManagementUserPath(userInput, userId))
  }, [history, userInput, userId])

  React.useEffect(() => {
    loadKeycloakUsers(ajax, filter).subscribe((keycloakUsers) => {
      const nextKeycloakUserItems = keycloakUsers.users ? keycloakUsers.users.map(convertKeycloakUserToAutoSuggest) : []
      setKeycloakUserItems(nextKeycloakUserItems)
    })
  }, [filter])

  const onSelect = (user: KeycloakUser) => {
    history.push(userManagementUserPath(filter, user.id))
  }

  return (
    <SearchInput
      inputValue={userInput}
      items={keycloakUserItems}
      placeholder="Filter by Name"
      search={() => undefined}
      reload={(prefix: string) => setDebouncedUserInput(prefix)}
      isSelectedSuggestion={(suggestion) => suggestion.user.id === userId}
      selectSuggestion={(suggestion) => onSelect(suggestion.user)}
      renderItem={(item) => <UserListItem user={item.user} filter={filter} />}
      renderItemIcon={() => <PersonIcon />}
      styleConfig={{borderRadius: 0}}
      styles={SEARCH_INPUT_STYLE}
      alwaysShowMenu
    />
  )
}
