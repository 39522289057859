import {Box, Flex} from '@indoqa/style-system'
import {useDebouncedUserInput} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {ajax} from 'rxjs/ajax'
import {Theme} from '../../../app/theme'
import {Button} from '../../../common/components/button/Button'
import {ModalWindow} from '../../../common/components/modal-window/ModalWindow'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {loadKeycloakUsers} from '../../state/user-management.services'
import {KeycloakUser, KeycloakUsers, UserManagementUser} from '../../state/user-management.types'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {UserSelectorItem} from './UserSelectorItem'

interface Props {
  show: boolean
  toggleShow: (nextShow: boolean) => void
  onSelect: (user: UserManagementUser) => void
}

export interface UserAutoCompleteItem extends AutoCompleteItem {
  user: KeycloakUser
}

function mapUsers(keycloakUsers: KeycloakUsers): UserAutoCompleteItem[] {
  if (!keycloakUsers || !keycloakUsers.users) {
    return []
  }

  return keycloakUsers.users.map((user) => ({
    text: user.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    user,
  }))
}

export function UserSelector({show, toggleShow, onSelect}: Props) {
  const {theme} = useFela<Theme>()
  const [userInput, setUserInput] = React.useState<string>('')
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)
  const [users, setUsers] = React.useState<UserAutoCompleteItem[]>([])

  React.useEffect(() => {
    setUserInput('')
  }, [show])
  React.useEffect(() => {
    if (show) {
      loadKeycloakUsers(ajax).subscribe((keycloakUsers) => {
        setUsers(mapUsers(keycloakUsers))
      })
    }
  }, [userInput, show])

  return (
    <ModalWindow
      show={show}
      onRequestClose={() => toggleShow(false)}
      header={
        <Flex
          fullWidth
          fullHeight
          bg={theme.colors.border}
          textAlign="left"
          pl={2}
          fontSize="small"
          alignItems="center"
          bold
        >
          <Box>Wähle einen Benutzer</Box>
        </Flex>
      }
      footer={
        <Flex fullWidth justifyContent="flex-end" alignItems="baseline">
          <Box pr={4} fontSize="small" underline onClick={() => toggleShow(false)} cursorPointer>
            Abbrechen
          </Box>
          <Button
            name="Hinzufügen"
            onClick={() => {
              toggleShow(false)
            }}
            selected={false}
          />
        </Flex>
      }
    >
      <Flex p={3} fullWidth fullHeight>
        <Box fullWidth overflowHidden fullHeight>
          <SearchInput
            inputValue={userInput}
            items={users}
            placeholder="Filtern nach Namen"
            search={() => undefined}
            reload={(prefix: string) => setDebouncedUserInput(prefix)}
            selectSuggestion={(suggestion) => {
              toggleShow(false)
              onSelect(suggestion.user)
            }}
            renderItem={(item) => <UserSelectorItem user={item.user} filterValue={userInput} />}
            styleConfig={{borderRadius: 0}}
            styles={SEARCH_INPUT_STYLE}
            alwaysShowMenu
          />
        </Box>
      </Flex>
    </ModalWindow>
  )
}
