import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {hideAll} from 'tippy.js'

import {AdminLayoutContext, AdminLayoutContextValue} from './AdminLayoutContext'

export const AdminLayoutContent: React.FC = ({children}) => {
  const {navShown, scrollToTop} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (scrollToTop && containerRef.current) {
      if (containerRef.current.scrollTo) {
        containerRef.current.scrollTo({top: 0})
      }
    }
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', () => hideAll())
    }
  })

  const contentStyle: IStyle = {
    display: navShown ? 'none' : 'flex',
    overflowY: 'auto',
    tablet: {
      display: 'flex',
    },
    print: {
      display: 'block',
      height: 'auto',
      overflow: 'visible',
      width: '100%',
    },
  }

  return (
    <Flex fullHeight grow={1} style={contentStyle} innerRef={containerRef} direction="column">
      {children}
    </Flex>
  )
}
