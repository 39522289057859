import {Flex, Text} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useCallback} from 'react'
import * as Rx from 'rxjs'
import {ajax} from 'rxjs/ajax'
import {catchError, filter, map, switchMap} from 'rxjs/operators'
import {UserDetailsPanel} from '../components/user/UserDetailsPanel'
import {UserListPanel} from '../components/user/UserListPanel'
import {UserManagementLayout} from '../layout/UserManagementLayout'
import {
  addUserPermission$,
  addUserToUserGroup$,
  loadUserGroup$,
  loadUsers$,
  removeUserFromUserGroup$,
  removeUserPermission$,
} from '../state/user-management.services'
import {Permission, UserGroup, UserManagementResource, UserManagementUser} from '../state/user-management.types'
import {BV_REQUEST_PARAMS} from '../user-management.paths'

interface UserData {
  user: UserManagementUser
  userGroups: UserGroup[]
  error?: string
}
export const UserManagementUserPage = () => {
  const userId = useQueryParam(BV_REQUEST_PARAMS.userId)

  const [user, setUser] = React.useState<UserManagementUser | null>(null)
  const [userGroups, setUserGroups] = React.useState<UserGroup[]>([])
  const [loading, setLoading] = React.useState(true)

  const loadUser = useCallback(() => {
    Rx.of(userId)
      .pipe(
        filter((id) => id != null), // Only proceed if userId is not null or undefined
        switchMap((id) => loadUsers$(ajax, id)), // Load the user
        switchMap((user) => {
          const userGroup = user.userGroups && user.userGroups.length > 0 ? user.userGroups[0] : null
          if (userGroup) {
            return loadUserGroup$(ajax, userGroup.id).pipe(
              map((userGroupDetails) => ({user, userGroups: [userGroupDetails]})) // Prepare the combined user and userGroup data
            )
          } else {
            return Rx.of<UserData>({user, userGroups: []})
          }
        }),
        catchError((error) => {
          console.error(error)
          return Rx.of({error})
        })
      )
      .subscribe({
        next: ({user, userGroups, error}: UserData) => {
          if (error) {
            // Handle error state
            setLoading(false)
            return
          }
          // Set the user and user groups if no error
          setUser(user)
          setUserGroups(userGroups)
          setLoading(false)
        },
        error: (err) => {
          // Additional error handling if needed
          console.error('An unexpected error occurred:', err)
          setLoading(false)
        },
      })
  }, [userId])

  const addPermission = React.useCallback(
    (resource: UserManagementResource) => {
      addUserPermission$(ajax, userId!, resource).subscribe(() => {
        loadUser()
      })
    },
    [loadUser, userId]
  )
  const removePermission = React.useCallback(
    (permission: Permission) => {
      removeUserPermission$(ajax, userId!, permission).subscribe(() => {
        loadUser()
      })
    },
    [loadUser, userId]
  )

  const addUserFromUserGroup = React.useCallback(
    (userGroup: UserGroup, role: string) => {
      addUserToUserGroup$(ajax, userId!, userGroup, role).subscribe(() => {
        loadUser()
      })
    },
    [loadUser, userId]
  )
  const removeUserFromUserGroup = React.useCallback(
    (userGroup: UserGroup) => {
      removeUserFromUserGroup$(ajax, userId!, userGroup).subscribe(() => {
        loadUser()
      })
    },
    [loadUser, userId]
  )

  React.useEffect(() => {
    loadUser()
  }, [loadUser])

  const contentPanel = user ? (
    <UserDetailsPanel
      user={user}
      userGroups={userGroups}
      addPermission={addPermission}
      removePermission={removePermission}
      addUserToUserGroup={addUserFromUserGroup}
      removeUserFromUserGroup={removeUserFromUserGroup}
    />
  ) : userId && !loading ? (
    <Flex fullHeight fullWidth center>
      Der Benutzer mit der ID&nbsp;<Text color="red">{userId}</Text>&nbsp;existiert nicht.
    </Flex>
  ) : null

  return <UserManagementLayout contentPanel={contentPanel} listPanel={<UserListPanel userId={userId} />} />
}
