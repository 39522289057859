import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ajax} from 'rxjs/ajax'
import {AdminLayoutContext, AdminLayoutContextValue} from '../../admin/layout/AdminLayoutContext'
import {Table} from '../../common/components/table/Table'
import {cellForceWrap, cellWidth, formatDate, formatDateTime} from '../../common/components/table/table.utils'
import {TableRow} from '../../common/components/table/TableRow'
import {loadArBooks} from '../state/ar.services'
import {ArBooksResultDocument, ArSearchResult} from '../state/ar.types'

export const ARfBRBooksPage = () => {
  const [books, setBooks] = React.useState<ArSearchResult<ArBooksResultDocument> | null>(null)
  const {setScrollToTop} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  React.useEffect(() => {
    setScrollToTop(new Date())
    loadArBooks(ajax, 0, 100).subscribe(setBooks)
  }, [setScrollToTop])

  const headers = ['Titel', 'Quelle (lang)', 'Rechtsstand', 'Uploaded']
  const renderRows = () => {
    return (
      <>
        {books?.documents?.map((doc, index) => {
          const cellValues = [
            doc.name,
            doc.properties.quelle_lang,
            formatDate(doc.properties.rechtstand),
            formatDateTime(doc.properties.uploaded),
          ]
          const cellFormats = [
            [cellWidth(350)],
            [cellForceWrap()],
            [cellWidth(140), cellForceWrap()],
            [cellWidth(180), cellForceWrap()],
          ]
          return (
            <TableRow
              key={index}
              index={index}
              entryJson={JSON.stringify(doc, null, 2)}
              cellValues={cellValues}
              cellFormats={cellFormats}
            />
          )
        })}
      </>
    )
  }
  return (
    <Box>
      <Table headers={headers}>{renderRows()}</Table>
    </Box>
  )
}
