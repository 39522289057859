import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../../app/theme'
import {baseCellStyle, cellJsonCodeStyle, cellWidth} from './table.utils'

interface Props {
  entryJson?: string
  index: number
  cellFormats: any
  cellValues: string[]
}

export const TableRow = ({index, cellValues, cellFormats, entryJson}: Props) => {
  const {css, theme} = useFela<Theme>()
  const [showDetails, setShowDetails] = React.useState(false)
  const trStyle: IStyle = {
    backgroundColor: index % 2 === 0 ? theme.colors.bgContentEmphasised : 'transparent',
    cursor: 'pointer',
  }
  return (
    <>
      <tr className={css(trStyle)} onClick={() => setShowDetails(!showDetails)}>
        {cellValues.map((value, i) => (
          <td key={i} className={css(baseCellStyle(theme), cellFormats[i])}>
            {value}
          </td>
        ))}
        {entryJson && (
          <td className={css(baseCellStyle(theme), cellWidth(80))}>
            <Text<Theme> color="secondary" underline>
              [Details]
            </Text>
          </td>
        )}
      </tr>
      {entryJson && showDetails && (
        <tr className={css(trStyle, {cursor: 'text'})}>
          <td colSpan={5}>
            <pre className={css(cellJsonCodeStyle())}>{entryJson}</pre>
          </td>
        </tr>
      )}
    </>
  )
}
