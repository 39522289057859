import * as React from 'react'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {HandleNext, useObservable} from '../use-observable/useObservable'

export type SetUserInput = (value: string) => void

export const useDebouncedUserInput = (
  setUserInput: SetUserInput,
  debounceTimeInMs: number = 275
): HandleNext<string> => {
  const [inputObservable$, handleNext] = useObservable<string>()
  React.useEffect(() => {
    inputObservable$.pipe(debounceTime(debounceTimeInMs), distinctUntilChanged()).subscribe(setUserInput)
  }, [inputObservable$])

  return handleNext
}
