import {ActionsObservable, combineEpics, ofType, StateObservable} from 'redux-observable'
import {ajax} from 'rxjs/ajax'
import {takeUntil} from 'rxjs/operators'
import {indexingEpics} from '../indexing/store/indexing.epics'
import history from './history'
import {RootAction, RootState} from './types'

export const services = {
  ajax,
  scheduler: undefined,
  history,
}

// prettier-ignore
const combinedEpics = combineEpics(
  ...indexingEpics,
)

// prettier-ignore
const rootEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => {
  return combinedEpics(action$, state$, services).pipe(
    takeUntil(action$.pipe(
      ofType('END'),
    )),
  )
}

export default rootEpic
