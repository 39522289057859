export const BASE_PATH = '/indexing'

export const indexingPathParams = {
  type: 'type',
  subType: 'subType',
}

export const indexingPath = (type: string) => `${BASE_PATH}/${type}`
export const indexingWithSubTypePath = (type: string, subtype: string | null) => {
  if (subtype === null) {
    return indexingPath(type)
  }
  return `${BASE_PATH}/${type}/${subtype}`
}
