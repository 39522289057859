import * as React from 'react'

export const CLASS_NAME_READER = 'cp-reader'

export const useEnableBodyClassReader = () => {
  React.useLayoutEffect(() => {
    const body = document.getElementsByTagName('body')
    if (body && body[0] && !body[0].classList.contains(CLASS_NAME_READER)) {
      body[0].classList.add(CLASS_NAME_READER)
    }
    return () => {
      if (body && body[0] && body[0].classList.contains(CLASS_NAME_READER)) {
        body[0].classList.remove(CLASS_NAME_READER)
      }
    }
  }, [])
}
