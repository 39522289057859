import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {useFela} from 'react-fela'
import {NavLink} from 'react-router-dom'
import {Theme} from '../../app/theme'

interface Props {
  to: string
}

export const AdminNavLink: React.FC<Props> = ({to, children}) => {
  const {theme} = useFela<Theme>()
  const style: any = {
    '& a': {
      textDecoration: 'none',
      color: theme.colors.text,
    },
    '& a.active': {
      fontWeight: 'bold',
      color: theme.colors.accent,
    },
  }
  return (
    <Box style={style}>
      <NavLink to={to}>{children}</NavLink>
    </Box>
  )
}
