import {Box} from '@indoqa/style-system'
import {ProtectedComponent} from '@oegbv/ui-shared'
import * as React from 'react'
import {AdminFooter} from '../components/AdminFooter'
import {AdminHeader} from '../components/AdminHeader'
import {AdminNavigation} from '../components/AdminNavigation'
import {AdminLayout} from '../layout/AdminLayout'

export const AdminPage: React.FC = ({children}) => {
  return (
    <ProtectedComponent>
      <AdminLayout header={<AdminHeader />} footer={<AdminFooter />} nav={<AdminNavigation />}>
        <Box minHeight="100%">{children}</Box>
      </AdminLayout>
    </ProtectedComponent>
  )
}
