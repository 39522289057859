import {Box} from '@indoqa/style-system'
import {Recommendation} from '../state/aem.types'
import {AemSearchResultItemPanel} from './AemSearchResultItemPanel'

interface Props {
  recommendation: Recommendation
}

export const RecommendationPanel = ({recommendation}: Props) => {
  return (
    <Box>
      <Box bold color="red">
        Recommendation: topic={recommendation.category}
      </Box>
      <AemSearchResultItemPanel resultDocument={recommendation.document} />
    </Box>
  )
}
