import {Box, Flex} from '@indoqa/style-system'
import {useDebouncedUserInput} from '@oegbv/ui-shared'
import * as React from 'react'
import {useFela} from 'react-fela'
import {ajax} from 'rxjs/ajax'
import {Theme} from '../../../app/theme'
import {Button} from '../../../common/components/button/Button'
import {ModalWindow} from '../../../common/components/modal-window/ModalWindow'
import {SearchInput} from '../../../common/components/search-input/SearchInput'
import {AutoCompleteItem, AutoCompleteItemTargetType} from '../../../common/components/search-input/SearchInput.types'
import {loadUserGroups$} from '../../state/user-management.services'
import {UserGroup, UserManagementUserGroups} from '../../state/user-management.types'
import {SEARCH_INPUT_STYLE} from '../commons/SearchInput.style'
import {UserGroupSelectorItem} from './UserGroupSelectorItem'

interface Props {
  show: boolean
  toggleShow: (nextShow: boolean) => void
  onSelect: (userGroup: UserGroup) => void
}

export interface UserGroupAutoCompleteItem extends AutoCompleteItem {
  userGroup: UserGroup
}

function mapUserGroups(userGroups: UserManagementUserGroups): UserGroupAutoCompleteItem[] {
  if (!userGroups || !userGroups.userGroups) {
    return []
  }

  return userGroups.userGroups.map((userGroup) => ({
    text: userGroup.id,
    proposal: '',
    targetType: AutoCompleteItemTargetType.SUGGESTION,
    userGroup,
  }))
}

export function UserGroupSelector({show, toggleShow, onSelect}: Props) {
  const {theme} = useFela<Theme>()
  const [userInput, setUserInput] = React.useState<string>('')
  const setDebouncedUserInput = useDebouncedUserInput(setUserInput)
  const [userGroups, setUserGroups] = React.useState<UserGroupAutoCompleteItem[]>([])

  React.useEffect(() => {
    setUserInput('')
  }, [show])
  React.useEffect(() => {
    if (show) {
      loadUserGroups$(ajax).subscribe((userGroups) => {
        setUserGroups(mapUserGroups(userGroups))
      })
    }
  }, [userInput, show])

  return (
    <ModalWindow
      show={show}
      onRequestClose={() => toggleShow(false)}
      header={
        <Flex
          fullWidth
          fullHeight
          bg={theme.colors.border}
          textAlign="left"
          pl={2}
          fontSize="small"
          alignItems="center"
          bold
        >
          <Box>Wähle eine Benutzergruppe</Box>
        </Flex>
      }
      footer={
        <Flex fullWidth justifyContent="flex-end" alignItems="baseline">
          <Box pr={4} fontSize="small" underline onClick={() => toggleShow(false)} cursorPointer>
            Abbrechen
          </Box>
          <Button
            name="Hinzufügen"
            onClick={() => {
              toggleShow(false)
            }}
            selected={false}
          />
        </Flex>
      }
    >
      <Flex p={3} fullWidth fullHeight>
        <Box fullWidth overflowHidden fullHeight>
          <SearchInput
            inputValue={userInput}
            items={userGroups}
            placeholder="Filtern nach Namen"
            search={() => undefined}
            reload={(prefix: string) => setDebouncedUserInput(prefix)}
            selectSuggestion={(suggestion) => {
              toggleShow(false)
              onSelect(suggestion.userGroup)
            }}
            renderItem={(item) => <UserGroupSelectorItem userGroup={item.userGroup} filterValue={userInput} />}
            styleConfig={{borderRadius: 0}}
            styles={SEARCH_INPUT_STYLE}
            alwaysShowMenu
          />
        </Box>
      </Flex>
    </ModalWindow>
  )
}
