import {Box, Flex} from '@indoqa/style-system'
import React from 'react'
import {DeleteIcon} from '../../../common/icons/DeleteIcon'
import {PersonGroupIcon} from '../../../common/icons/PersonGroupIcon'
import {UserGroup} from '../../state/user-management.types'

interface Props {
  userGroup: UserGroup
  removeUserFromUserGroup: (userGroup: UserGroup) => void
}

export function UserGroupItem({userGroup, removeUserFromUserGroup}: Props) {
  const [hover, setHover] = React.useState(false)

  return (
    <Flex pt={1} onMouseOver={() => setHover(true)} fullWidth htmlAttrs={{onMouseLeave: () => setHover(false)}}>
      <Box style={{position: 'relative', top: -4}} pr={2} grow={0}>
        <PersonGroupIcon />
      </Box>
      <Flex fontSize="small" grow={1}>
        {userGroup.name}
        {hover && (
          <Box
            ml={2}
            style={{position: 'relative', top: -2}}
            cursorPointer
            onClick={() => removeUserFromUserGroup(userGroup)}
          >
            <DeleteIcon />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
