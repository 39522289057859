import {Flex, Text} from '@indoqa/style-system'
import {Theme} from '../../../app/theme'

interface Props {
  currentPage: number
  pageSize: number
  documentsFound: number
  goToPage: (nextPage: number) => void
}

export const TablePaging = ({currentPage, pageSize, documentsFound, goToPage}: Props) => {
  const needsMorePages = documentsFound > pageSize
  if (!needsMorePages) {
    return null
  }
  const hasPreviousPage = currentPage > 0
  const hasNextPage = currentPage * pageSize < documentsFound
  return (
    <Flex fullWidth center>
      {hasPreviousPage && (
        <Text<Theme> color="secondary" underline cursorPointer onClick={() => goToPage(currentPage - 1)}>
          Previous page
        </Text>
      )}
      {!hasPreviousPage && <Text>Previous page</Text>}
      <Text mx={2}>|</Text>
      {hasNextPage && (
        <Text<Theme> color="secondary" underline cursorPointer onClick={() => goToPage(currentPage + 1)}>
          Next page
        </Text>
      )}
      {!hasNextPage && <Text>Next page</Text>}
    </Flex>
  )
}
