import {useState} from 'react'
import {BehaviorSubject, Subject} from 'rxjs'

export type HandleNext<T> = (value: T) => void
export type UseObservableResult<T> = [Subject<T>, HandleNext<T>]

/*
 * see https://brycedooley.com/creating-useObservable-hook/
 */
export const useObservable = <T>(initialState?: T): UseObservableResult<T> => {
  const subject = initialState ? new BehaviorSubject<T>(initialState) : new Subject<T>()
  const [observable] = useState(subject)
  const handleNext = (value: T) => {
    observable.next(value)
  }
  return [observable, handleNext]
}
