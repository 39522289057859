import {Epic, ofType} from 'redux-observable'
import {map, switchMap} from 'rxjs/operators'
import {RootState, Services} from '../../app/types'
import {IndexingAction, IndexingActionKeys, IndexingLoadEntries, indexingLoadEntriesSuccess} from './indexing.actions'
import {loadAemEntries, loadEntries} from './indexing.services'
import {AemSubtypes, ProviderTypes} from './indexing.types'

interface IndexingEpic extends Epic<IndexingAction, IndexingAction, RootState, Services> {}

const loadOverview$: IndexingEpic = (action$, state, {ajax}) =>
  action$.pipe(
    ofType<IndexingLoadEntries>(IndexingActionKeys.LOAD_ENTRIES),
    switchMap((action) => {
      const {providerType, providerSubType, start, count} = action
      if (providerType === ProviderTypes.AEM) {
        return loadAemEntries(ajax, start, count, providerSubType || AemSubtypes.ALL).pipe(
          map((result) => indexingLoadEntriesSuccess(result))
        )
      }
      return loadEntries(ajax, start, count, providerType).pipe(map((result) => indexingLoadEntriesSuccess(result)))
    })
  )

export const indexingEpics = [loadOverview$]
