import Keycloak, {KeycloakInitOptions, KeycloakLoginOptions, KeycloakProfile} from 'keycloak-js'
import {getInitialState} from '../../initial-state'
import {removeBearerTokenCookie, writeBearerTokenCookie} from '../BearerTokenCookieWriter'
import {AuthenticatedUser, Authenticator, Permission} from './Authenticator'

interface User {
  username: string
  firstname?: string
  lastname?: string
  email?: string
  admin: boolean
  arfbr: 'FREE' | 'PLUS' | 'PREMIUM'
  books: boolean
  kv: boolean
  lohndatenApi: boolean
}

export class KeycloakAuthenticator implements Authenticator {
  keycloakAdapterInstance = Keycloak(getInitialState().conf.auth)
  profile: KeycloakProfile | null = null
  initialized = false
  user: User | null = null

  init(done: () => void) {
    removeBearerTokenCookie()
    const initOptions: KeycloakInitOptions = {
      onLoad: 'check-sso',
      // silentCheckSsoRedirectUri: `${window.location.origin}/check-login.html`,
      enableLogging: true,
      checkLoginIframe: false,
      // checkLoginIframe: false,
    }
    // because of CP-565 the keycloakAdapter does not check the loginIFrame
    // this means that there is no single-sign out at the moment
    // see https://issues.redhat.com/browse/KEYCLOAK-12125 for further details
    this.keycloakAdapterInstance
      .init(initOptions)
      .then((authenticated) => {
        this.initialized = true

        // console.group('Keycloak has been initialized')
        // console.log('authToken', this.getToken())
        // console.log('refreshToken', this.keycloakAdapterInstance.refreshToken)
        // console.log('refreshTokenParsed', this.keycloakAdapterInstance.refreshTokenParsed)
        // console.log('idToken', this.keycloakAdapterInstance.idToken)
        // console.log('idTokenParsed', this.keycloakAdapterInstance.idTokenParsed)
        // console.groupEnd()

        writeBearerTokenCookie(this.getToken())

        if (authenticated) {
          // console.log('Keycloak has successfully authenticated the user.', this.keycloakAdapterInstance)
          setInterval(() => {
            this.keycloakAdapterInstance
              .updateToken(15 * 60) // refresh 15 minutes before the auth token becomes invalid (6.11.2023: auth token has a validity of 30 minutes)
              .then((result) => {
                // console.group('Keycloak update cycle')
                // console.log('token refreshed', result)
                // console.log('authToken', this.getToken())
                // console.log('refreshToken', this.keycloakAdapterInstance.refreshToken)
                // console.log('refreshTokenParsed', this.keycloakAdapterInstance.refreshTokenParsed)
                // console.groupEnd()

                writeBearerTokenCookie(this.getToken())
              })
              .catch((e) => {
                // console.group('Error while refreshing the authentication token.', e)
                // console.log('refreshToken', this.keycloakAdapterInstance.refreshToken)
                // console.log('refreshTokenParsed', this.keycloakAdapterInstance.refreshTokenParsed)
                // console.groupEnd()

                this.keycloakAdapterInstance.login()
              })
          }, 10_000)

          const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${this.getToken()}`,
          }
          fetch('/api/v1/user', {headers})
            .then((res) => {
              if (res.ok) {
                res
                  .json()
                  .then((user) => {
                    this.user = user
                    done()
                  })
                  .catch((e) => {
                    done()
                    console.error('Cannot read user from REST response', e)
                  })
              } else {
                done()
              }
            })
            .catch((e) => {
              done()
              console.error('Cannot fetch user', e)
            })
        } else {
          done()
        }
      })
      .catch((e) => {
        console.error('ÖGB SSO failed.', e)
        done()
      })
  }

  isInitialized(): boolean {
    return this.initialized
  }

  getProfile(): KeycloakProfile | null {
    if (!this.initialized) {
      return null
    }
    return this.profile
  }

  getToken(): string | undefined {
    if (!this.isAuthenticated()) {
      return undefined
    }
    return this.keycloakAdapterInstance.token
  }

  getTokenType(): string | undefined {
    return 'Bearer'
  }

  hasGroup(groupName: string): boolean {
    if (!this.isAuthenticated()) {
      return false
    }
    if (!this.keycloakAdapterInstance.tokenParsed) {
      return false
    }
    const groups = this.keycloakAdapterInstance.tokenParsed['groups:name']
    if (groups) {
      return groups.includes(groupName)
    }
    return false
  }

  hasRole(testNames: string[]) {
    if (!this.isAuthenticated()) {
      return false
    }
    if (!this.keycloakAdapterInstance.tokenParsed) {
      return false
    }
    const realmAccess = this.keycloakAdapterInstance.tokenParsed['realm_access']
    if (!realmAccess) {
      return false
    }
    const roles = realmAccess['roles']
    if (!roles || !Array.isArray(roles)) {
      return false
    }
    for (let i = 0; i <= testNames.length; i++) {
      const nextTestName = testNames[i]
      if (roles.includes(nextTestName)) {
        return true
      }
    }
    return false
  }

  getUserAttribute(attributeName: string): string | any[] | boolean | null {
    if (!this.isAuthenticated()) {
      return null
    }
    if (!this.keycloakAdapterInstance.tokenParsed) {
      return null
    }
    const user = this.keycloakAdapterInstance.tokenParsed['user']
    if (!user) {
      return null
    }
    const value = user[attributeName]
    if (typeof value === 'string' || Array.isArray(value) || typeof value === 'boolean') {
      return value
    }
    return null
  }

  hasOegbVerlagEmailDomain(): boolean {
    if (!this.isAuthenticated()) {
      return false
    }
    if (!this.keycloakAdapterInstance.tokenParsed) {
      return false
    }

    const email: string = this.keycloakAdapterInstance.tokenParsed['email'] || ''
    return email.toUpperCase().endsWith('@OEGBVERLAG.AT')
  }

  hasPermission(permissions: Permission | Permission[]) {
    if (!this.user) {
      return false
    }
    if (Array.isArray(permissions)) {
      for (const eachPermission of permissions) {
        if (this.validatePermission(eachPermission)) {
          return true
        }
      }
      return false
    } else {
      return this.validatePermission(permissions)
    }
  }

  private validatePermission(permission: Permission) {
    if (!this.user) {
      return false
    }

    if (permission === Permission.KVSYSTEM) {
      return this.user.kv
    }

    if (permission === Permission.ARFBR_FREE) {
      return this.user.arfbr === 'FREE'
    }

    if (permission === Permission.ARFBR_PLUS) {
      return this.user.arfbr === 'PLUS'
    }

    if (permission === Permission.ARFBR_PREMIUM) {
      return this.user.arfbr === 'PREMIUM'
    }

    if (permission === Permission.BOOKS) {
      return this.user.books
    }

    if (permission === Permission.SETTINGS) {
      return this.user.admin
    }

    if (permission === Permission.CP_ADMIN_ALL) {
      return this.user.admin
    }

    if (permission === Permission.CP_ADMIN_INDEXING) {
      if (this.user.admin || this.hasRole(['CPAdminIndexer', 'CPAdmin'])) {
        return true
      }
    }
    if (permission === Permission.CP_ADMIN_LOHNDATEN) {
      if (this.hasRole(['LohndatenApiAdmin', 'CPAdmin'])) {
        return true
      }
    }
    if (permission === Permission.API_LOHNDATEN) {
      return this.user.lohndatenApi
    }

    return false
  }

  isUserName(testNames: string[]) {
    if (!this.isAuthenticated()) {
      return false
    }
    if (!this.keycloakAdapterInstance.tokenParsed) {
      return false
    }
    const user = this.keycloakAdapterInstance.tokenParsed['user']
    if (!user) {
      const email = this.keycloakAdapterInstance.tokenParsed['email']
      for (let i = 0; i < testNames.length; i++) {
        const nextTestName = testNames[i]
        if (nextTestName === email) {
          return true
        }
      }
      return false
    }
    const username = user['username']
    if (!username) {
      return false
    }
    for (let i = 0; i < testNames.length; i++) {
      const nextTestName = testNames[i]
      if (nextTestName === username) {
        return true
      }
    }
    return false
  }

  isAuthenticated(): boolean {
    if (!this.isInitialized()) {
      return false
    }
    return this.isInitialized() && this.keycloakAdapterInstance.authenticated === true
  }

  login(broker?: string, redirectUri?: string | null): void {
    if (!this.isInitialized()) {
      return
    }
    const loginProps: KeycloakLoginOptions = {}
    if (broker) {
      loginProps.idpHint = broker
    }
    if (redirectUri) {
      loginProps.redirectUri = redirectUri
    }
    this.keycloakAdapterInstance.login(loginProps)
  }

  logout(redirectUri?: string | null): void {
    removeBearerTokenCookie()

    if (!this.isInitialized()) {
      return
    }
    this.keycloakAdapterInstance.logout({redirectUri: redirectUri || window.location.origin})
  }

  currentUser(): AuthenticatedUser | undefined {
    if (!this.isAuthenticated()) {
      return undefined
    }
    const tokenParsed = this.keycloakAdapterInstance.tokenParsed
    if (!tokenParsed) {
      return undefined
    }
    const user = tokenParsed['user']
    if (!user) {
      return undefined
    }
    const id = tokenParsed['sub'] || ''
    const name = user['username']
    const email = user['email']
    return {
      id,
      email,
      name,
    }
  }
}

const containsValue = (values: string | any[] | boolean | null, value: string) => {
  if (!Array.isArray(values)) {
    return false
  }
  return values.includes(value)
}

const containsValues = (
  values1: string | any[] | boolean | null,
  value1: string,
  values2: string | any[] | boolean | null,
  value2: string
) => {
  if (!Array.isArray(values1) || !Array.isArray(values2)) {
    return false
  }
  const count = Math.min(values1.length, values2.length)
  for (let i = 0; i < count; i++) {
    if (value1 === values1[i] && value2 === values2[i]) {
      return true
    }
  }
  return false
}
