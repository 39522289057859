import {authenticator} from '@oegbv/ui-shared'

const PARAM_SET = 'set'

export const getHeaders = () => {
  const headers = {
    Accept: 'application/json',
  }

  const currentSet = getCurrentSetFromSessionStorage()
  if (currentSet === 'PREVIEW') {
    headers['set'] = currentSet
  }

  const token = authenticator.getToken()
  if (token) {
    Object.assign(headers, {Authorization: `Bearer ${token}`})
  }
  return headers
}

export const getCurrentSetFromSessionStorage = () => {
  const set = window.sessionStorage.getItem(PARAM_SET)
  if (!set) {
    return 'LIVE'
  }
  return set
}

export const saveCurrentSetToSessionStorage = (set: string) => {
  window.sessionStorage.setItem(PARAM_SET, set)
}
