export interface IndexingSearchResult {
  queryTime: number
  executionTime: number
  documentsFound: number
  documents?: IndexingResultDocument[]
}

export interface IndexingResultDocument {
  id: string
  provider: string
  name: string
  location: string
  indexed: Date
  shortText: string
  properties: {[key: string]: string}
}

export enum ProviderTypes {
  ALL = 'all',
  KV = 'kv',
  BOOKS = 'books',
  AEM = 'aem',
  RIS = 'ris',
  OWCS = 'owcs',
  WEBC = 'webc',
}

export enum AemSubtypes {
  ALL = 'all',
  OEGB = 'oegb',
  GPA = 'gpa',
  YOUNION = 'younion',
  SICHEREARBEIT = 'sicherearbeit',
  OEGJ = 'oegj',
}
