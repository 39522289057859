import {BaseCssProps} from '@indoqa/style-system'
import {OegbvBaseTheme} from '..'

export const baseCssProps = (theme: OegbvBaseTheme): BaseCssProps => {
  return {
    spacing: {
      space1: theme.spacing.space1,
      space2: theme.spacing.space2,
    },
    fontSizes: {
      text: theme.fontSizes.text,
      h1: theme.fontSizes.extraBig,
      h2: theme.fontSizes.veryBig,
      h3: theme.fontSizes.big,
    },
    fontStyles: {
      base: theme.fontStyles.base,
      alt: {
        ...theme.fontStyles.base,
        fontWeight: 700,
      },
    },
    links: {
      base: theme.colors.secondary,
      hover: theme.colors.secondary,
      active: theme.colors.secondary,
      visited: theme.colors.secondary,
    },
    colors: {
      text: theme.colors.text,
    },
  }
}
