import {Box} from '@indoqa/style-system'
import {addMonths} from 'date-fns'
import addMinutes from 'date-fns/addMinutes'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

const ISO_FORMAT_NO_TZ = "yyyy-MM-dd'T'HH:mm:ss'z'"
const MONTH_FORMAT = 'yyyy-MM'

const dateToUTC = (date: Date) => {
  const utcDate = addMinutes(date, date.getTimezoneOffset())
  return format(utcDate, ISO_FORMAT_NO_TZ)
}
const createYearOptions = () => {
  const options: React.ReactNode[] = []
  for (let year = new Date().getFullYear(); year >= 2020; year--) {
    options.push(
      <option key={year} value={year}>
        {String(year)}
      </option>
    )
  }
  return options
}

export const BooksUsageStatisticsPage = () => {
  const {css, theme} = useFela<Theme>()
  const [year, setYear] = React.useState(String(addMonths(new Date(), -1).getFullYear()))
  const [month, setMonth] = React.useState(String(addMonths(new Date(), -1).getMonth()))

  const startDate = new Date(parseInt(year, 10), parseInt(month, 10), 1)
  const isoStartDateUTC = dateToUTC(startDate)

  const endDate = endOfMonth(startDate)
  const isoEndDate = dateToUTC(endDate)

  const cellStyleClass = css({
    paddingTop: theme.spacing.space2,
    paddingRight: theme.spacing.space2,
  })
  return (
    <Box p={4}>
      <h3>Bücher Nutzungsstatistik</h3>
      <table>
        <tbody>
          <tr>
            <td className={cellStyleClass}>Year</td>
            <td>
              <select
                value={year}
                onChange={(e) => {
                  setYear(e.target.value)
                }}
              >
                {createYearOptions()}
              </select>
            </td>
          </tr>
          <tr>
            <td className={cellStyleClass}>Month</td>
            <td>
              <select
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value)
                }}
              >
                <option value={0}>Jänner</option>
                <option value={1}>Februar</option>
                <option value={2}>März</option>
                <option value={3}>April</option>
                <option value={4}>Mai</option>
                <option value={5}>Juni</option>
                <option value={6}>Juli</option>
                <option value={7}>August</option>
                <option value={8}>September</option>
                <option value={9}>Oktober</option>
                <option value={10}>November</option>
                <option value={11}>Dezember</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <Box pt={2}>
        <a
          download={`kv-usage_${format(startDate, MONTH_FORMAT)}.xls`}
          href={`/api/v1/book/tracking?from=${isoStartDateUTC}&to=${isoEndDate}`}
        >
          Download
        </a>
      </Box>
    </Box>
  )
}
