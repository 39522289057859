import {Box, Flex} from '@indoqa/style-system'
import React from 'react'
import {DeleteIcon} from '../../../common/icons/DeleteIcon'
import {PermissionIcon} from '../../../common/icons/PermissionIcon'
import {Permission} from '../../state/user-management.types'

interface Props {
  permission: Permission
  group?: boolean
  removePermission?: (permission: Permission) => void
}

export function PermissionItem({permission, group, removePermission}: Props) {
  const [hover, setHover] = React.useState(false)
  return (
    <Flex pt={1} onMouseOver={() => setHover(true)} fullWidth htmlAttrs={{onMouseLeave: () => setHover(false)}}>
      <Box style={{position: 'relative', top: -4}} pr={2} grow={0}>
        <PermissionIcon color={group ? 'orange' : undefined} />
      </Box>
      <Flex fontSize="small" grow={1}>
        {permission.resourceName}
        {hover && !group && (
          <Box
            ml={2}
            style={{position: 'relative', top: -2}}
            cursorPointer
            onClick={() => removePermission?.(permission)}
          >
            <DeleteIcon />
          </Box>
        )}
      </Flex>
    </Flex>
  )
}
