import {Box} from '@indoqa/style-system'
import * as React from 'react'
import {ajax} from 'rxjs/ajax'
import {AdminLayoutContext, AdminLayoutContextValue} from '../../admin/layout/AdminLayoutContext'
import history from '../../app/history'
import {Table} from '../../common/components/table/Table'
import {cellForceWrap, cellNoWrap, cellWidth, formatDateTime} from '../../common/components/table/table.utils'
import {TablePaging} from '../../common/components/table/TablePaging'
import {TableRow} from '../../common/components/table/TableRow'
import {useCurrentPage} from '../../common/components/table/useCurrentPage'
import {arfbrWebCPath} from '../arfbr.paths'
import {loadArWebCDocuments} from '../state/ar.services'
import {ArSearchResult, ArWebCResultDocument} from '../state/ar.types'

const PAGE_SIZE = 50

export const ARfBRWebCPage = () => {
  const currentPage = useCurrentPage()
  const [result, setResult] = React.useState<ArSearchResult<ArWebCResultDocument> | null>(null)
  const {setScrollToTop} = React.useContext<AdminLayoutContextValue>(AdminLayoutContext)
  React.useEffect(() => {
    setScrollToTop(new Date())
    loadArWebCDocuments(ajax, 0, PAGE_SIZE).subscribe(setResult)
  }, [setScrollToTop])

  if (!result) {
    return null
  }

  const goToPage = (nextPage: number) => {
    const link = arfbrWebCPath()
    history.push(link + (nextPage > 0 ? `?p=${nextPage}` : ''))
  }

  const headers = ['Indexed at', 'Provider', 'Name', 'WebC-Type']
  const renderRows = () => {
    return (
      <>
        {result.documents?.map((doc, index) => {
          const cellValues = [formatDateTime(doc.indexed), doc.provider, doc.name, doc.properties.webcType]
          const cellFormats = [
            [cellNoWrap(), cellWidth(150)],
            [cellWidth(80)],
            [cellWidth(250), cellForceWrap()],
            [cellForceWrap()],
          ]
          return (
            <TableRow
              key={index}
              index={index}
              entryJson={JSON.stringify(doc, null, 2)}
              cellValues={cellValues}
              cellFormats={cellFormats}
            />
          )
        })}
      </>
    )
  }
  return (
    <Box>
      <Table headers={headers}>{renderRows()}</Table>
      <TablePaging
        goToPage={goToPage}
        pageSize={PAGE_SIZE}
        documentsFound={result.documentsFound ?? 0}
        currentPage={currentPage}
      />
    </Box>
  )
}
