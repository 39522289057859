import {Box, Flex, VStack} from '@indoqa/style-system'
import {nanoid} from 'nanoid'
import React from 'react'
import Switch from 'react-switch'
import {AddIcon} from '../../../common/icons/AddIcon'
import {
  Permission,
  UserGroup,
  UserGroupUpdate,
  UserManagementResource,
  UserManagementUser,
} from '../../state/user-management.types'
import {DetailsHeader} from '../commons/DetailsHeader'
import {DetailsSection} from '../commons/DetailsSection'
import {PermissionDetails} from '../permissions/PermissionDetails'
import {UserDetailsField} from '../user/UserDetailsField'
import {UserItem} from '../user/UserItem'
import {UserSelector} from '../user/UserSelector'

interface Props {
  userGroup: UserGroup | null
  addPermission: (kvResource: UserManagementResource) => void
  removePermission: (permission: Permission) => void
  addUserToUserGroup: (user: UserManagementUser) => void
  removeUserFromUserGroup: (user: UserManagementUser) => void
  saveUserGroup: (userGroup: UserGroupUpdate) => void
}

export function UserGroupDetailsPanel({
  userGroup,
  addPermission,
  removePermission,
  addUserToUserGroup,
  removeUserFromUserGroup,
  saveUserGroup,
}: Props) {
  const [showUserSelector, setShowUserSelector] = React.useState(false)

  const updateName = React.useCallback(
    (name: string) => {
      if (userGroup) {
        saveUserGroup({
          id: userGroup.id,
          name,
          disabled: !!userGroup.disabled,
        })
      } else {
        saveUserGroup({
          id: nanoid(21),
          name,
          disabled: false,
        })
      }
    },
    [userGroup, saveUserGroup]
  )
  const updateDisabled = React.useCallback(
    (disabled: boolean) => {
      if (!userGroup) {
        return
      }
      saveUserGroup({
        id: userGroup.id,
        name: userGroup.name!,
        disabled,
      })
    },
    [userGroup, saveUserGroup]
  )

  return (
    <Flex direction="column" nowrap>
      <DetailsHeader>{userGroup ? userGroup.name || userGroup.id : 'Neue Benutzergruppe'}</DetailsHeader>

      <DetailsSection name="Gruppenname">
        <UserDetailsField value={userGroup?.name || ''} onSave={updateName}></UserDetailsField>
      </DetailsSection>

      {userGroup && (
        <>
          <DetailsSection name="Aktiv">
            <Box pt={2}>
              <Switch
                height={20}
                width={50}
                onChange={(changed: boolean) => {
                  updateDisabled(!changed)
                }}
                checked={!userGroup?.disabled}
              />
            </Box>
          </DetailsSection>

          <DetailsSection
            name="Benutzer"
            actionItem={
              !userGroup?.users || userGroup?.users.length === 0 ? (
                <Box onClick={() => setShowUserSelector(true)}>
                  <AddIcon />
                </Box>
              ) : undefined
            }
          >
            <VStack pt={2} pl={2}>
              {userGroup.users?.map((user) => {
                return <UserItem user={user} removeUserFromUserGroup={removeUserFromUserGroup} />
              })}
            </VStack>
            {(!userGroup.users || userGroup.users.length === 0) && (
              <Box pl={2} fontSize="small">
                Keine
              </Box>
            )}
          </DetailsSection>

          <PermissionDetails
            permissions={userGroup.permissions}
            addPermission={addPermission}
            removePermission={removePermission}
          />

          <UserSelector show={showUserSelector} toggleShow={setShowUserSelector} onSelect={addUserToUserGroup} />
        </>
      )}
    </Flex>
  )
}
