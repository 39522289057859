import {Action} from 'redux'
import {IndexingSearchResult} from './indexing.types'

export enum IndexingActionKeys {
  LOAD_ENTRIES = 'indexing/LOAD_ENTRIES',
  LOAD_ENTRIES_SUCCESS = 'indexing/LOAD_ENTRIES_SUCCESS',
}

export type IndexingAction = IndexingLoadEntries | IndexingLoadEntriesSuccess

export interface IndexingLoadEntries extends Action {
  type: IndexingActionKeys.LOAD_ENTRIES
  providerType: string
  providerSubType: string | null
  start: number
  count: number
}

export const indexingLoadEntries = (
  providerType: string,
  providerSubType: string | null,
  start: number,
  count: number
): IndexingLoadEntries => ({
  type: IndexingActionKeys.LOAD_ENTRIES,
  providerType,
  providerSubType,
  start,
  count,
})

export interface IndexingLoadEntriesSuccess extends Action {
  type: IndexingActionKeys.LOAD_ENTRIES_SUCCESS
  result: IndexingSearchResult
}

export const indexingLoadEntriesSuccess = (result: IndexingSearchResult): IndexingLoadEntriesSuccess => ({
  type: IndexingActionKeys.LOAD_ENTRIES_SUCCESS,
  result,
})
